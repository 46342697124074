import React from 'react';
import { Row, Col, Image, Typography } from 'antd';
import title_handmade from "./img/SVG/title_handmade.svg";
import slider from "./img/slider_5.gif";
import Citation from "./Citation.jsx";

const { Text } = Typography;

export default function NatureAbout(props) {

    return (
        <div id="about" className="container">
          <Image src={title_handmade} height={"170px"} preview={false}/>
          <div>
            <Citation 
                citation="Nature is everything, and we are part of nature."
                author=""
            />
            <Row>
                <Col md={6} sm={0}>
                </Col>
                <Col md={6} sm={12}>
                <Image className="nftgallery-img" src={slider} preview={false}/>
                </Col>
{/*                 <Col span={18}>
                    <p className="about-text">
                        <span className="about-emphasis">Nature Oracle</span> is a small and exclusive collection of 143 NFT Oracle Cards on Fantom Chain.
                    </p>
                    <p className="about-text">
                    <span className="about-emphasis">Nature Oracle</span> is one of the first mintable Art collection on Fantom Opera and combines 100% original, no-pfp Art with the utility of a smart contract.
                    </p>
                    <p className="about-text">
                        <span className="about-emphasis">Its art is inspired by Nature and the timeless tradition of Oracle cards. Each card is a 1/1 fully unique NFT. Our artists spent countless hours creating the concept art for each card.</span>
                    </p>
                    <p className="about-text">
                    <span className="about-emphasis">The utility of the Nature Oracle comes with each Oracle card being an ERC-721 NFT token.</span>{"\n"}
                    The smart contract gives the owner of the NFT the full copyright of the art and allows Nature Oracle to be listed on NFT marketplaces as a collection. 
                    Each Oracle card has its own rarity attribute and prediction. The predictions are hand-written and 100% original, inspiring and unique.
                    </p>
                </Col>
 */}            
                <Col md={12} sm={12}>
                    <p className="about-text">
                        <span className="about-emphasis">143</span> Nature Oracle cards only.
                    </p>
                    <p className="about-text">
                    <span className="about-emphasis">13</span> pre-minted.
                    </p>
                    <p className="about-text">
                    High Quality Art, hand-painted &amp; unique.{"\n"}
                    Oracle reading spreads, periodic raffles.{"\n"}
                    OG Collection on Fantom of <span className="about-emphasis">Zoey</span>
                    </p>
                    <p className="about-text">
                        <span className="about-emphasis">We sold out !</span> Find us on <a href="https://paintswap.finance/marketplace/collections/0x600ea64f353085a0df6fba7335086951ee90f36f" target="_blank">Paintswap</a> or <a href="https://nftkey.app/collections/natureoracle/" target="_blank">NFTKEY</a>{"\n"}
                        <span className="about-emphasis">Verified contract on Fantom Opera </span>
                        <Text copyable={{ text: "0x600ea64f353085a0Df6fba7335086951ee90f36f" }} style={{fontSize: "18px"}}>0x600ea64f353085a0Df6fba7335086951ee90f36f</Text>
                    </p>
                </Col>
            </Row>
          </div>
        </div>
    );
  }
  