import React from 'react';
import { Row, Col, Image, Typography } from 'antd';
import title_handmade from "./img/SVG/title_handmade.svg";
//import slider from "./img/witches.gif";
import slider from "./img/witches_anim.gif";
import Citation from "./Citation.jsx";

const { Text } = Typography;

export default function WitchesAbout(props) {

    return (
        <div id="about" className="container">
          <Image src={title_handmade} height={"170px"} preview={false}/>
          <div>
            <Citation 
                citation="For I am the soul of Nature Who gives life to the Universe. From Me all things proceed, and unto Me they must return."
                author="D. Valiente"
            />
            {/* <Row>
                <Col md={4} sm={0}>
                </Col>
                <Col md={8} sm={24}>
                <Image className="nftgallery-img" src={slider} preview={false}/>
                </Col>
                <Col md={12} sm={24}>
                    <p className="about-text">
                        <span className="about-emphasis">168</span> Witches Oracle cards only.
                    </p>
                    <p className="about-text">
                    <span className="about-emphasis">8</span> pre-minted.
                    </p>
                    <p className="about-text">
                        <span className="about-emphasis">90</span> FTM presale mint price.{"\n"}
                        <span className="about-emphasis">150</span> FTM public sale mint price. 
                    </p>
                    <p className="about-text">
                    High-quality art - a unique process combining painting and digital art{"\n"}
                    <span className="about-emphasis">Free airdrop</span> to holders of our future <span className="about-emphasis">witches-inspired</span> collection {"\n"}
                    Periodic raffles to win <span className="about-emphasis">$ftm</span> from our treasury fund.{"\n"}
                    Collectible traits games, rewards &amp; collector tokens to come in 2022
                    </p>
                    <p className="about-text">
                        <span className="about-emphasis">Verified contract on Fantom Opera</span> 
                        <Text copyable={{ text: "0x0Fb9d7CC9a3FbFc62A71182552a576859Ae0c758" }} style={{fontSize: "16px"}}> 0x0Fb9d7CC9a3FbFc62A71182552a576859Ae0c758</Text>
                    </p>
                </Col>
            </Row> */}
            <Row>
                <Col md={24} sm={24}>
                <Image className="nftgallery-img" src={slider} preview={false}/>
                </Col>
            </Row>
            <p className="timeline-text">
                High-quality art - a unique process combining painting and digital art{"\n"}
            </p>
            <p className="timeline-text">
                <span className="emphasis-witches">Zoey</span> talks about her art, her process, her inspiration, and the symbolism behind her new collection in her <a href="https://medium.com/@NatureOracle/witches-oracle-nft-ama-with-the-artist-zoey-e929cd96eabc" target="_blank">AMA on Medium.</a>
            </p>
            {/* <p className="timeline-text">
                Find all the details about the art, the perks, the launch and the presale conditions in our <a href="https://medium.com/@NatureOracle/witches-oracle-nft-official-release-announcement-on-fantom-chain-bd49d60a2c36" target="_blank">Medium article.</a>
            </p>
            <p className="timeline-text">
                New to Fantom Opera chain? Discover how to bridge <span className="about-emphasis">$ftm</span> into the ecosystem on <a href="https://docs.spookyswap.finance/products/bridge" target="_blank">Spookyswap.</a>
            </p> */}
          </div>
        </div>
    );
  }
  