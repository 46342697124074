import React from "react";
import title_faq from "./img/SVG/title_faq.svg";
import { Image, Collapse } from 'antd';
import Citation from "./Citation.jsx";
import decor2 from "./img/decor_2.jpg";

const { Panel } = Collapse;
// displays the FAQ

export default function Faq(props) {
  return (
    <div id="faq" className="container">
        <Image src={title_faq} height={"170px"} preview={false}/>
        <Citation 
            citation="There is something infinitely healing in the repeated refrains of nature - the assurance that dawn comes after night, and spring after winter."
            author="Rachel Carson"
        />
        <Image className="nature-decor" src={decor2} preview={false}/>        
        <div className="faq-container">
            <div className="faq-block">
                <div className="faq-header">
                    <span>What chain is the collection on?</span>
                </div>
                <div>
                    <p>
                    Each NFT is an ERC-721 Token under our Nature Oracle smart contract.
                    We choose the Fantom Opera chain for its low fees, amazing community and its mystic theme which matches the one of our collection.
                    </p>
                </div>
            </div>

            <div className="faq-block">
                <div className="faq-header">
                    <span>How many Oracle cards are there?</span>
                </div>
                <div>
                    <p>
                    Nature Oracle is a very small collection of 143 mintable NFTs.
                    We aspire to offer a truly rare, unique and exclusive art collection.
                    </p>
                </div>
            </div>

            <div className="faq-block">
                <div className="faq-header">
                    <span>Is it a PFP collection? </span>
                </div>
                <div>
                    <p>
                    No, every card is individually hand-drawn and then meticulously reworked digitally, offering the highest quality of art. The mint will be in random order for the cards.
                    </p>
                </div>
            </div>

            <div className="faq-block">
                <div className="faq-header">
                    <span>When will the collection open for minting?</span>
                </div>
                <div>
                    <p>
                    The mint date is November 30, 2021 at 9PM CST.
                    </p>
                </div>
            </div>

            <div className="faq-block">
                <div className="faq-header">
                    <span>What is the price for each NFT?</span>
                </div>
                <div>
                    <p>
                    The price is 50 FTM. We strive to offer a fair compensation for our artists and also a fair price for our minters: similar handmade and original no-pfp Artworks usually sell for a minimum of 1-2 times this price on marketplaces.
                    The marketplace fee is 7%, it will allow us to have the resource to continuing building the universe and offering super exciting perks for our NFT holders.
                    </p>
                </div>
            </div>
    
            <div className="faq-block">
                <div className="faq-header">
                    <span>Have the cards meanings?</span>
                </div>
                <div>
                    <p>
                    Yes! At the end of the minting process, each NFT owner will be able to discover the rarity and the prediction of their Oracle card on our website. The predictions are fully handwritten and original.
                    </p>
                </div>
            </div>

            <div className="faq-block">
                <div className="faq-header">
                    <span>What is the difference between an Oracle and a Tarot deck?</span>
                </div>
                <div>
                    <p>
                    Tarot cards are very conventional in their style with fixed interpretation and imagery. More personal and free-style version of prediction cards are called "Oracle cards".
                    We use this medium so our artists have the full freedom to express their visions.
                    </p>
                </div>
            </div>
            
{/*             <Collapse>
            <Panel className="faq-block faq-header" header="What is the difference between an Oracle and a Tarot deck?" key="1">
                <p>{"Tarot cards are very conventional in their style with fixed interpretation and imagery. More personal and free-style version of prediction cards are called \"Oracle cards\".\
                    We use this medium so our artists have the full freedom to express their visions."}
                </p>
            </Panel>
            </Collapse>
 */}
        </div>
    </div>
  );
}
