import { Alert, Button } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { notification } from "antd";
import { useContractLoader } from "../hooks";
import { Row, Col, Image } from 'antd';
import {  Typography  } from 'antd';
import { Transactor } from "../helpers";
import { parseEther } from "@ethersproject/units";

import title_glimpse from "./img/SVG/title_coven_bleed.svg";
//import title_glimpse from "./img/SVG/title_coven_swamp.svg";

const { Text } = Typography;

const DEBUG = false;
const TRAVERSE_ENABLED = true;
//const TRAVERSE_CHAIN_DESTINATION = 10012;   // ftmtest
//const TRAVERSE_CHAIN_DESTINATION = 12;   // Fantom Opera Mainnet
const TRAVERSE_CHAIN_DESTINATION = 112;   // Fantom Opera Mainnet - ChainID update LayerZero early Sept 2022

var myHeaders = new Headers();
myHeaders.append('pragma', 'no-cache');
myHeaders.append('cache-control', 'no-cache');

var myInit = {
  method: 'GET',
  headers: myHeaders,
}; 

// rewrite ipfs:// uris to dweb.link gateway URLs
function makeGatewayURL(ipfsURI) {
  return ipfsURI.replace(/^ipfs:\/\//, "https://natureoracle.mypinata.cloud/ipfs/");
}

async function fetchJSON(ipfsURI) {
    const url = makeGatewayURL(ipfsURI);
    const resp = await fetch(url);
    if (resp.ok) {
        return resp.json();
    }
    else {
        return undefined;
    }
    
}

function maskShowroom(title, rank) {
  return (
      <div>
          <p className="nftreading-mask-title">
            {title}
          </p>
          <p className="nftreading-mask-description">
            Token #{rank}
          </p>
      </div>
  )
}

async function getListOwnedBO(address, contract, nftBalance) {
   
    let tokenmetadata = [];
    // Loop over all the token own and record their Ids
    for (let i = 0; i < nftBalance; i++) {
        if (address) {
            let tokenid = await contract.tokenOfOwnerByIndex(address, i);
            let metadataURI = await contract.tokenURI(tokenid.toNumber());
            let metadata = await fetchJSON(metadataURI);
            DEBUG && console.log(metadata);
            if (metadata) {
                tokenmetadata.push(metadata);
            }
        }
    }
    
    return tokenmetadata;
}

async function traverseNFT({contract, ownerAddress, provider, gasPrice, setStatus}, tokenID) {

  // scaffold-eth's Transactor helper gives us a nice UI popup when a transaction is sent
  const transactor = Transactor(provider, gasPrice);
  let tx;
  DEBUG && console.log("Traversing" , tokenID);
  tx = await transactor(contract.traverseChains(TRAVERSE_CHAIN_DESTINATION, tokenID, { value: parseEther("0.2")}));

  setStatus("Traversing in progress, waiting confirmation...");

  // Wait for the transaction to be confirmed, then get the token ID out of the emitted Transfer event.
  try {
    const receipt = await tx.wait();
    if (!receipt) {
        setStatus(`The transaction was rejected`);
        return 0;
    }
    let tokenId = null;
    for (const event of receipt.events) {
        if (event.event !== 'Transfer') {
            continue
        }
        else {
          tokenId = event.args.tokenId.toString();
          break;
      }
    }
    setStatus(`Haunted BitWitches #${tokenId} traversed to Fantom`);
    return tokenId;
  } catch (e) {
        console.log(e);
        notification.error({
            message: "Transaction Rejected",
            description: e.message,
        });
        setStatus("Transaction rejected");
  }
}


export default function BitWitchesRitualsGallery({
  nftbalance,
  provider,
  address,
  web3Modal,
  signer,
  gasPrice,
}) {
  const contracts = useContractLoader(provider);
  let contract;
  contract = contracts ? contracts["BitWitchesRituals"] : "";
  const nftBalance = nftbalance ? nftbalance.toNumber() : 0;
  
  const [notificationFired, setNotificationFired] = useState(false);
  const [walletConnected, setWalletConnected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [traversing, setTraversing] = useState(false);
  const [status, setStatus] = useState("");
  const [traverseAlert, setTraverseAlert] = useState("");

  // Entry counting related states
  const [listOwnedBO, setListOwnedBO] = useState([]);

  if (address && contract && nftBalance && (listOwnedBO.length < nftBalance)){
      // Try to retrieve the list of owned Banners Oracle token
      //DEBUG && console.log('tokens owned: ', list);
      let add = address;
      let cnt = contract;
      let bal = nftBalance;
      getListOwnedBO(add, cnt, bal).then(tokenlist => {
        setListOwnedBO(tokenlist);
        setWalletConnected(true);
        setLoading(false);
        setErrorMessage("");
        DEBUG && console.log('tokens owned: ', tokenlist);
      }).catch(e => {
        console.log('error getting token: ', e);
        setLoading(false);
        setErrorMessage(e.message);
        //setListOwnedBO([]);
      })
  }

  useEffect(() => {
    if (web3Modal) {
        if (!web3Modal.cachedProvider) {
           // no wallet is connected
           if (!notificationFired) {
              setNotificationFired(true);
              notification.info({
                message: "Please connect Wallet",
                description: "A wallet connected to Fantom Opera is required for minting",
                duration: 60,
                placement: "bottomRight",
              });
            }
        }
        else {
            setWalletConnected(true);
        }
      }    
  }, [])

  const startTraversing = (tokenid) => {
    setTraversing(true);
    signer.getAddress().then(ownerAddress => {
      traverseNFT({ 
        contract, 
        provider, 
        ownerAddress, 
        gasPrice, 
        setStatus
      },
      tokenid
      ).then(newTokenId => {
        setTraversing(false);
        setTraverseAlert(transAlertPopup);
        console.log('traversing complete');
      })
    });
  }

  const transAlertPopup = (
    <div style={{ zIndex: 2, position: "fixed", right: 0, top: 60, padding: 16 }}>
      <Alert
        message={"Traversing Successful"}
        description={
          <div>
            Your NFT was successfully traversed to Fantom.
          </div>
        }
        type="success"
        closable={true}
      />
    </div>
  );

  // Create the collection of owner Witches Oracles
  let BOCollection = (
    <div>
        <Row><Col md={24} sm={24}>You will be able to discover your minted BitWitches Rituals here...</Col></Row>
    </div>
  );

  //, item.rarity.rank

  if (!loading && listOwnedBO) {
    let columnwidth = 8;
    if (listOwnedBO.length === 1) {
        columnwidth = 24;
    }
    else if (listOwnedBO.length === 2) {
        columnwidth = 12;
    }


    BOCollection = (
      <Row>
        {listOwnedBO.map(item => (
          <Col md={columnwidth} sm={24}>
            <Image className="nftgallery-bitwitches-img" src={makeGatewayURL(item.image)} preview={{ mask: maskShowroom(item.name, item.id), maskClassName: "nftreading-mask-style"}}/>
            <div>
              { TRAVERSE_ENABLED && 
              <Button className="button-minting-increment" disabled={traversing} type="primary" onClick={()=>startTraversing(item.id)}>
                {traversing ? <LoadingOutlined/> : "Traverse to Fantom"}
              </Button>
              }
            </div>
          </Col>
        ))}
      </Row>
    );
  }

return (
    <div id="nft-gallery">
        <div className="container">
            <div id="nft-banner-gallery-body">
                {/* Add here a title - your Witches Oracle*/}
                <Image src={title_glimpse} height={"170px"} preview={false} style={{margin: "auto"}}/>
                {traverseAlert}
                {BOCollection}
                {errorMessage}
                {status}
            </div>
        </div>
    </div>
  );}
