import { Statistic, Tabs, Button } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { notification } from "antd";
import { useContractLoader } from "../hooks";
import { Row, Col, Image } from 'antd';
import {  Typography  } from 'antd';

import title_raffle from "./img/SVG/title_raffle.svg";
import Citation from "./Citation.jsx";
import Separator from "./Separator.jsx";
import { dictNatureOraclePrediction } from './listnft';

const { TabPane } = Tabs;
const { Countdown } = Statistic;
const { Text } = Typography;

const DEBUG = true;

const RAFFLE_DURATION = 5;      // duration in days
const RAFFLE_ID = 1;            // increment manually for each raffle
const RAFFLE_PRIZE = "10 FTM"   // prize or reward
const RAFFLE_START = new Date(Date.UTC(2022, 1, 10, 2, 0, 0, 0)); // Remember that month January starts at 0 - UTC time - CST = UTC - 6H | 21h CST is 3 0 0 the day after
const RAFFLE_END = new Date(Date.UTC(2022, 1, 10, 2, 0, 0, 0)); // Remember that month January starts at 0
RAFFLE_END.setDate(RAFFLE_END.getDate() + RAFFLE_DURATION);

async function getListOwnedNATO(address, contract, nftBalance) {
   
    let tokenlist = [];
    // Loop over all the token own and record their Ids
    for (let i = 0; i < nftBalance; i++) {
        let tokenid = await contract.tokenOfOwnerByIndex(address, i);
        tokenlist.push(tokenid.toNumber());
    }
    
    return tokenlist;
}

// rewrite ipfs:// uris to dweb.link gateway URLs
function makeGatewayURL(ipfsURI) {
    //return ipfsURI.replace(/^ipfs:\/\//, "https://dweb.link/ipfs/");
    return ipfsURI.replace(/^ipfs:\/\//, "https://gateway.pinata.cloud/ipfs/");
  }
  
async function fetchIPFSJSON(ipfsURI) {
    const url = makeGatewayURL(ipfsURI);
    const resp = await fetch(url);
    return resp.json();
}
  
async function getListOwnedNATO2(address, contract, nftBalance) {
   
    let tokenrarities = [];
    // Loop over all the token own and record their Ids
    for (let i = 0; i < nftBalance; i++) {
        if (address) {
            let tokenid = await contract.tokenOfOwnerByIndex(address, i);
            let metadataURI = await contract.tokenURI(tokenid.toNumber());
            let metadata = await fetchIPFSJSON(metadataURI);
            let rarity = metadata.attributes[0].value;
            tokenrarities.push(rarity);
        }
    }
    
    return tokenrarities;
}

function getNumberEntries(tokenlist) {
    DEBUG && console.log('list', tokenlist);
    // Create the array of rarity
    var keys = Object.keys(dictNatureOraclePrediction);
    let rarities = {}
    for (let key in dictNatureOraclePrediction) {
        rarities[dictNatureOraclePrediction[key].id] = dictNatureOraclePrediction[key].rarity;
    }

    //DEBUG && console.log('rarities', rarities);

    let nbentries = 0;
    let tokenid = 0;
    var rarity;
    for (let i in tokenlist) {
        tokenid = tokenlist[i];
        if (tokenid in rarities) {
            // The token is in the list, find its rarity
            rarity = rarities[tokenid];
            if (rarity === "Common") {
                nbentries += 1;
            }
            else if (rarity === "Uncommon") {
                nbentries += 2;
            }
            else if (rarity === "Rare") {
                nbentries += 4;
            }
            else if (rarity === "Epic") {
                nbentries += 5;
            }
            //DEBUG && console.log('step', tokenid, nbentries);
        }
    }

    return nbentries;
}

function getNumberEntries2(tokenrarities) {
    DEBUG && console.log('list', tokenrarities);

    let nbentries = 0;
    var rarity;
    for (let i in tokenrarities) {
        rarity = tokenrarities[i];
        if (rarity === "Common") {
            nbentries += 1;
        }
        else if (rarity === "Uncommon") {
            nbentries += 2;
        }
        else if (rarity === "Rare") {
            nbentries += 4;
        }
        else if (rarity === "Epic") {
            nbentries += 6;
        }
        DEBUG && console.log('step', rarity, nbentries);
    }

    return nbentries;
}
export default function Verification({
  userProvider,
  nftbalance,
  provider,
  address,
  web3Modal
}) {
  const contracts = useContractLoader(provider);
  let contract;
  contract = contracts ? contracts["NatureOracleNFTMinter"] : "";
  //console.log('nft readings address', address);
  //const address = contract ? contract.address : "";
  const nftBalance = nftbalance ? nftbalance.toNumber() : 0;
  // Eligible to participate with at least one
  const eligibilityConfirmed = nftBalance >= 1;

  const [notificationFired, setNotificationFired] = useState(false);
  const [walletConnected, setWalletConnected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  // Raffle activation related states
  const [raffleStarted, setRaffleStarted] = useState(new Date() >= RAFFLE_START);
  const [raffleLive, setRaffleLive] = useState((new Date() >= RAFFLE_START) && (new Date() < RAFFLE_END));
  const [raffleFinished, setRaffleFinished] = useState(new Date() >= RAFFLE_END);

  // Entry counting related states
  const [listOwnedNATO, setListOwnedNATO] = useState([]);
  const [numberEntries, setNumberEntries] = useState(0);
  const [entryResponse, setEntryResponse] = useState(null);
  const [bEntryReceivedAlready, setbEntryReceivedAlready] = useState(false);

  const [discordUser, setDiscordUser] = useState();

//   if (web3Modal) {
//     if (!web3Modal.cachedProvider) {
//        // no wallet is connected
//        if (!notificationFired) {
//           setNotificationFired(true);
//           notification.info({
//             message: "Please connect Wallet",
//             description: "A Wallet with at least 1 NATO is required to access advanced reading spreads",
//             duration: 60,
//             placement: "bottomRight",
//           });
//         }
//     }
//     else {
//         setWalletConnected(true);
//     }
//   }

  if (address && contract && nftBalance && (listOwnedNATO.length === 0)){
      // Try to retrieve the list of owned Nature Oracle token
      //setLoading(true);
      //let list = getListOwnedNATO(address, contract, nftBalance);
      //DEBUG && console.log('tokens owned: ', list);
      let add = address;
      let cnt = contract;
      let bal = nftBalance;
      getListOwnedNATO2(add, cnt, bal).then(tokenlist => {

        // Check if wallet has already participated
        if (address) {
            for (let id in raffleItems) {
                if (raffleItems[id].WALLET_ADDRESS === address){
                    setbEntryReceivedAlready(true);
                    setNumberEntries(raffleItems[id].NB_ENTRIES);
                }
            }    
        }

        setListOwnedNATO(tokenlist);
        setWalletConnected(true);
        setLoading(false);
        setErrorMessage("");
        DEBUG && console.log('tokens owned: ', tokenlist);
        let nbentries;
        nbentries = getNumberEntries2(tokenlist);
        setNumberEntries(nbentries);
        DEBUG && console.log('entries: ', nbentries);
      }).catch(e => {
        console.log('error getting token: ', e);
        setLoading(false);
        setErrorMessage(e.message);
        setListOwnedNATO([]);
      })
  }

  useEffect(() => {
    // parse access_token and token_type that were inserted by Discord into redirect URL
    const fragment = new URLSearchParams(window.location.hash.slice(1));
    console.log(fragment);
    const [accessToken, tokenType] = [fragment.get('access_token'), fragment.get('token_type')];
    const fetchUsers = () => {
        fetch('https://discord.com/api/users/@me', {
          headers: {
            authorization: `${tokenType} ${accessToken}`,
          },
        })
        .then(result => result.json())
        .then(response => {
          // response format 
          console.log(response);
          /*
          {
                "id": "<user_id>",
                "username": "Poopeye",
                "avatar": "3118e64af30fc703b9bf3328d156155c",
                ...
            }
          */
          // user as avatar URL: `https://cdn.discordapp.com/avatars/${discordUser.id}/${discordUser.avatar}.png`
          setDiscordUser(response);
        })
        .catch(console.error);
    };
  
    if (accessToken) {
      fetchUsers();
    }
  }, []);

  const verifyOwnerGeneric = async () => {
    // const web3 = new Web3(currentProvider);
    // const mes = await web3.eth.personal.sign(`I'm a verified Nature Oracle or Witches Oracle owner!`, currentAccount);
    //const _signature = await signer.sign(`I'm a verified Nature Oracle or Witches Oracle owner!`, address);
    const _signature = await userProvider.send("personal_sign", ['hello', address]);
    console.log('signature', _signature);
    // window.location.href = `https://<endpoint_base_uri>/?&mes=${mes}&user=${discordUser.id}`;
  };

  // Query the raffle API to see current status
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [raffleItems, setRaffleItems] = useState([]);
  const [totalEntriesRecorded, setTotalEntriesRecorded] = useState(0);

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    fetch("https://retoolapi.dev/dNA641/natureoracle_raffle")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setRaffleItems(result);
          let totalentries = 0;
          for (let item in result) {
            totalentries += result[item].NB_ENTRIES;
          }
          DEBUG && console.log('entries found so far ', totalentries);
          setTotalEntriesRecorded(totalentries);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  useEffect(() => {
    if (web3Modal) {
        if (!web3Modal.cachedProvider) {
           // no wallet is connected
           if (!notificationFired) {
              setNotificationFired(true);
              notification.info({
                message: "Please connect Wallet",
                description: "A Wallet with at least 1 NATO is required to access advanced reading spreads",
                duration: 60,
                placement: "bottomRight",
              });
            }
        }
        else {
            setWalletConnected(true);
        }
      }    
  }, [])

  // Results from the Rest API query of current participants
  let apiResults = ""
  if (error) {
    apiResults = <div>Error: {error.message}</div>;
  } 
  else if (!isLoaded) {
    apiResults = <div>Loading...</div>;
  } 
  else {
    // Debug - display the results from the API query on current participants
    apiResults = (
      <ul>
        {raffleItems.map(item => (
          <li key={item.id}>
            {item.WALLET_ADDRESS} {item.NB_ENTRIES}
          </li>
        ))}
      </ul>
    );
  }

  const resetRaffle = () => {
    axios.delete('https://retoolapi.dev/dNA641/natureoracle_raffle/5');
    // const entry = { WALLET_ADDRESS: "default", 
    //                 NB_ENTRIES: 0 };
    // axios.patch('https://retoolapi.dev/dNA641/natureoracle_raffle/4',entry);
  }

  const resetRaffleButton = (
    <Button className="button-minting" type="primary" disabled={false} onClick={verifyOwnerGeneric}> 
      Sign Message
    </Button>
  )

  const enterRaffle = () => {
    // The number of entries is numberEntries
    // The address is in address

    // set a post request to the RestAPI with wallet address and the number of entries
    const entry = { WALLET_ADDRESS: address, 
                    NB_ENTRIES: numberEntries };

    axios.post('https://retoolapi.dev/dNA641/natureoracle_raffle', entry)
        .then(response => setEntryResponse('Entry id: ' + response.data.id + '. Good luck!'));
    // Post a confirmation of entries
    setbEntryReceivedAlready(true);
  }

  const enterRaffleButton = (
    <Button className="button-minting" type="primary" disabled={false} onClick={enterRaffle}>
      Record your participation to the Raffle!
    </Button>
  )

  const refreshOnFinish = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };  

  const countdownStart = (
    <Countdown className="raffle" title="THE RAFFLE WILL BEGIN IN..." value={RAFFLE_START} format="D d HH:mm:ss" onFinish={refreshOnFinish} />
  );

  const countdownEnd = (
    <Countdown className="raffle" title="THE RAFFLE WILL END IN..." value={RAFFLE_END} format="D d HH:mm:ss" onFinish={refreshOnFinish} />
  );

  const msgRaffleFinished = (
    <div>
        <Text>The raffle is over. Stay tune for the next one!</Text>
    </div>
  );

  const msgRaffleStatus = (
    <div>
        <span className="about-emphasis">{totalEntriesRecorded} tickets</span> have been entered in the raffle so far.
    </div>
  );

  const msgNoWallet = (
    <div>
        <Text>Please connect your wallet on Fantom Opera to determine eligibility</Text>
    </div>
  );

  const msgLoadingWallet = (
    <div>
        <div>
            <Text>Checking your balance of NATO tokens in your wallet...</Text>
        </div>
        <div>
            <LoadingOutlined/>
        </div>
    </div>
  );

  const msgNoNATOToken = (
    <div>
        <Text>Sorry, but no Nature Oracle NFTs were found in your wallet. Please check...</Text>
    </div>
  );

  const msgAlreadyParticipated = (
    <div>
        <div>
            Your entry of {numberEntries} tickets for this raffle has been recorded.
        </div>
        <div>
            Results will be announced shortly after the end of the raffle on Twitter. <span className="about-emphasis">Thank you!</span>{"\n"}
        </div>
    </div>
  );

  const rafflesection = (
    <div>
        <div>
        Hello there!
        </div>
        <div>
        We found <span className="about-emphasis">{nftBalance} Nature Oracle NFTs</span> in your wallet.
        </div>
        <div>
        Based on their rarity, you have received <span className="about-emphasis">{numberEntries} tickets</span> for this raffle.
        </div>        
        {enterRaffleButton}
    </div>
  );

  return (
    <section id="nft-readings">
        <div className="container">
            <img className="nft-reading-title" src={title_raffle}/>
            <div>
                <Citation 
                citation={"The cards, the cards, the cards will tell \n \
                The past, the present, and the future as well \n \
                The cards, the cards, just take three \n \
                Take a little trip into your future with me \n \
                "}
                author="Dr. Faclier, The princess and the frog"
                />
                <Row>
                    <Col md={6} sm={3}>
                    <Image className="nftreading-img" src="deck/A23Desert.jpg" preview={false} style={{maxWidth: "350px"}}/>
                    </Col>
                    <Col md={18} sm={21}>
                        <h2>
                        Welcome to Nature Oracle Raffle <span className="about-emphasis">#{RAFFLE_ID}</span>
                        </h2>
                        <p className="about-text">
                            The price for this week raffle is the Nature Oracle NFT <span className="about-emphasis">Truc</span>, featuring the famous <span className="about-emphasis">Dogecoin dog, </span>
                            one of the only 4 cards featuring it!! To participate, you need to own at least 1 Nature Oracle NFT (NATO).
                        </p>

                        {/* Display the countdown until start if the raffle has not yet started */}
                        {!raffleStarted && countdownStart}
                        {/* Display the countdown until end if the raffle has started but is not yet finished */}
                        {raffleStarted && !raffleFinished && countdownEnd}
                        {/* Display the current status of the raffle*/}
                        {raffleStarted && msgRaffleStatus}
                        {/* Display message that this raffle is finished */}
                        {raffleFinished && msgRaffleFinished}
                        <Separator 
                          id={2}
                          width={"200px"}
                        />
                        {/* Display message that you need to connect your wallet */}
                        {raffleLive && !walletConnected && msgNoWallet}
                        {/* Display message no NATO token were detected */}
                        {raffleLive && walletConnected && !loading && (nftBalance === 0) && msgNoNATOToken}
                        {/* If raffle has started but not yet finished, if eligible, display the participation content */}
                        {raffleStarted && !raffleFinished && eligibilityConfirmed && !bEntryReceivedAlready && rafflesection}
                        {/* If raffle has started but not yet finished, and still determining eligibility, display waiting */}
                        {raffleStarted && !raffleFinished && walletConnected && loading && msgLoadingWallet}
                        {/* If raffle has started and the connected wallet has already participated */}
                        <div>
                        {entryResponse}
                        </div>
                        {raffleStarted && bEntryReceivedAlready && msgAlreadyParticipated}                                          
                    </Col>
                </Row>
                <Row>
                    <Col md={18} sm={21}>
                        {/* Display a button when DEV to reset the records of the raffle participants */}
                        {DEBUG && resetRaffleButton}
                        <div>
                        {DEBUG && apiResults}
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    </section>
  );
}
