import { Button } from "antd";
import React from "react";

/*
  ~ What it does? ~

  Allows users to log in to existing accounts and log out

  ~ How can I use? ~

  <ConnectWallet
    web3Modal={web3Modal}
    loadWeb3Modal={loadWeb3Modal}
    logoutOfWeb3Modal={logoutOfWeb3Modal}
  />

  ~ Features ~

  - Provide web3Modal={web3Modal}, loadWeb3Modal={loadWeb3Modal}, logoutOfWeb3Modal={logoutOfWeb3Modal}
              to be able to log in/log out to/from existing accounts
*/

export default function ConnectWallet({
  address,
  web3Modal,
  loadWeb3Modal,
  logoutOfWeb3Modal,
}) {
  const modalButtons = [];
  var displayAddress;

  if (address) {
    displayAddress = address.substr(0, 6) + "..." + address.substr(-4);
  }
  else {
    displayAddress = "";  
  }

  if (web3Modal) {
    if (web3Modal.cachedProvider) {
      modalButtons.push(
        <Button
          key="logoutbutton"
          className="button-wallet"
          /*style={{ verticalAlign: "top", marginLeft: 8, marginTop: 4 }}*/
          shape="round"
          size="large"
          onClick={logoutOfWeb3Modal}
        >
          {displayAddress}
        </Button>,
      );
    } else {
      modalButtons.push(
        <Button
          key="loginbutton"
          className="button-wallet"
          /*style={{ verticalAlign: "top", marginLeft: 8, marginTop: 4 }}*/
          shape="round"
          size="large"
          /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          onClick={loadWeb3Modal}
        >
          CONNECT WALLET
        </Button>,
      );
    }
  }

  return (
    <div>
      {modalButtons}
    </div>
  );
}
