import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import ScrollElement from 'rc-scroll-anim/lib/ScrollElement';
import TweenOne from 'rc-tween-one';
import { DownOutlined } from '@ant-design/icons';
import { Statistic, Image, Tooltip } from 'antd';
import { Row, Col} from 'antd';
import ConnectWallet from "./ConnectWallet.jsx";
import FreeBannerMintButton from "./FreeBannerMintButton.jsx";

//import title_surprise from "./img/SVG/title_surprise.svg";
import slider from "./img/slider_5.gif";
import slider_witches from "./img/slider_witches.gif";
import title_surprise from "./img/SVG/title_bitwitches.svg";
import Citation from "./Citation.jsx";
import Separator from "./Separator.jsx";

// DEBUG FLAG - set to false in prod
const DEBUG = false;

const { Countdown } = Statistic;

// BANNERS ORACLE LAUNCH
// UNCOMMENT FOR PROD
const MINT_START = new Date(Date.UTC(2022, 4, 4, 21, 0, 0, 0)); //TBD
const MINT_PUBLIC_START = new Date(Date.UTC(2024, 2, 18, 20, 0, 0, 0)); //TBD

// TEST CODE
// const MINT_START = new Date(Date.UTC(2022, 4, 3, 23, 18, 30, 0)); //TBD
// const MINT_PUBLIC_START = new Date(Date.UTC(2022, 2, 18, 15, 57, 0, 0)); //18H UTC 1PM CST

const calculateTimeLeft = () => {
  let difference = MINT_PUBLIC_START - +new Date();

  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      in: "The public sale will open ",
      day: Math.floor(difference / (1000 * 60 * 60 * 24)),
      h: Math.floor((difference / (1000 * 60 * 60)) % 24),
      min: Math.floor((difference / 1000 / 60) % 60),
      s: Math.floor((difference / 1000) % 60)
    };
  }

  return timeLeft;
}

export default function FreeBannerMint({ 
  gasPrice,
  signer,
  provider,
  address,
  blockExplorer,
  totalSupply,
  price,
  presaleprice,
  balance,
  web3Modal,
  loadWeb3Modal,
  logoutOfWeb3Modal,
  whiteListEnabled,
  }) {

  // WITCHES ORACLE LAUNCH
  // UNCOMMENT FOR PROD
  const [isCountdownFinished, setIsCountdownFinished] = useState(new Date() >= MINT_START);
  //const [isCountdownFinished, setIsCountdownFinished] = useState(true);
  const [isPublicSaleEnabled, setIsPublicSaleEnabled] = useState(new Date() >= MINT_PUBLIC_START);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const mintRemaining = true;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCountdownFinished(new Date() >= MINT_START);
      setIsPublicSaleEnabled(new Date() >= MINT_PUBLIC_START);
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });  

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key="{interval}">
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  if (DEBUG) console.log("provider", provider.connection.url);
  if (DEBUG) console.log("address", address);
  if (DEBUG) console.log("countdown status", isCountdownFinished);
  
  const countdown = (
    <Countdown title="COUNTDOWN TO MINT" value={MINT_START} format="D d HH:mm:ss"/>
  );

  const countdownPublicSale = (
    <p>
    {timerComponents}
    </p>
  );

  const outOfNFTs = (
    <p className="mint-outofnft ant-statistic-content-value">
        Sold out! Find us on <a href="https://paintswap.finance/marketplace/collections/0x600ea64f353085a0df6fba7335086951ee90f36f" target="_blank">Paintswap</a>.
    </p>
  );

  return (
    <section id="nft-readings">
        <div className="container">
            <img className="nft-reading-title" src={title_surprise}/>
            <div>
                {/* <Citation 
                citation={"Ask and it will be given to you \n \
                Seek and you will find \n \
                Knock and the door will be opened to you \n \
                "}
                author="Matthew 7:7"
                /> */}
                <Row>
                  <Col>
                  <p key="content" className="content-witches">A collection of NFT Banners on Fantom by Zoey</p>
                  <Image src="img/banner_11pale8.png" preview={false}/> 
                  </Col>
                </Row>
                <Row>
                    <Col md={5} sm={0} xs={0}>
                      <Image className="nftgallery-img" src={slider} preview={false}/>
                    </Col>
                    <Col md={14} sm={24} xs={24}>
                    <p key="content" className="content-witches timeline-text">A Free mint for Nature Oracle + Witches Oracle holders</p>                                  
                    {!isCountdownFinished && countdown}
                    {/* {isCountdownFinished && !isPublicSaleEnabled && timerComponents.length && countdownPublicSale} */}
                    {/* <Tooltip title={"OperaHouse"} color={'#de767b'} key={'#de767b'} placement="bottom">
                      <a href="https://operahouse.online/collection/WitchesOracle" target="_blank"><Image className="paintswap-icon" width={"70px"} src={"img/oh.png"} preview={false}/></a>
                    </Tooltip>
                    <Tooltip title={"PaintSwap"} color={'#de767b'} key={'#de767c'} placement="bottom">
                    <a href="https://paintswap.finance/marketplace/collections/0x0fb9d7cc9a3fbfc62a71182552a576859ae0c758" target="_blank"><Image className="paintswap-icon" width={"70px"} src={"img/paintswap_icon.png"} preview={false}/></a>
                    </Tooltip> */}
                    {isCountdownFinished && provider.connection.url === 'unknown:' && mintRemaining && 
                      <ConnectWallet
                        address={address}
                        web3Modal={web3Modal}
                        loadWeb3Modal={loadWeb3Modal}
                        logoutOfWeb3Modal={logoutOfWeb3Modal}
                      />  
                    } 
                    {isCountdownFinished && (provider.connection.url !== 'unknown:' || !mintRemaining) && 
                      <FreeBannerMintButton
                        signer={signer}
                        provider={provider}
                        address={address}
                        blockExplorer={blockExplorer}
                        totalSupply={totalSupply}
                        price={price}
                        balance={balance}
                        whiteListEnabled={whiteListEnabled}
                        publicSaleEnabled={isPublicSaleEnabled}
                      />
                    } 
                    {isCountdownFinished && 
                      <p key="content" className="content-witches timeline-text">Find all banners on <a href="https://nftkey.app/collections/bitwitchesoraclebanners/" target="_blank">NFTKEY</a></p>
                    }
                    <Tooltip title={"Trade BitWitches:Banner on NFTKEY"} color={'#de767b'} key={'#de767d'} placement="bottom">
                    {isCountdownFinished && 
                      <a href="https://nftkey.app/collections/bitwitchesoraclebanners/" target="_blank"><Image className="paintswap-icon" height={"70px"} src={"img/nftkey.svg"} preview={false}/></a>
                    }
                    </Tooltip>                    
                    </Col>
                    <Col md={5} sm={0} xs={0}>
                    <Image className="nftgallery-img" src={slider_witches} preview={false}/>
                    </Col>
                  </Row>
                </div>
        </div>
    </section>
  );
}