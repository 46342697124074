import { DownloadOutlined, EditOutlined, SendOutlined, ClockCircleOutlined, CheckOutlined, EuroTwoTone } from "@ant-design/icons";
import { Row, Col, Image, Typography } from 'antd';
import React from "react";
import title_roadmap from "./img/SVG/title_roadmap.svg";
import Citation from "./Citation.jsx";

const { Text } = Typography;

// displays a timeline for scaffold-eth usage

export default function Roadmap(props) {
  return (
    <div id="roadmap">
        <div className="container">
        <Image src={title_roadmap} height={"170px"} preview={false}/>
            <div>
                <Citation 
                        citation="Let there be beauty and strength, power and compassion, honor and humility, mirth and reverence within you."
                        author="The charge of the Goddess"
                />
                <Row>
                    <Col md={6} sm={3}>
                    <Image src="img/moonfull_blue.png" preview={false} style={{maxWidth: "150px"}}/>
                    </Col>
                    <Col md={18} sm={21}>
                        <h2 style={{textAlign: "left"}}>
                        November-December 2021 - Launch of our <span className="about-emphasis">OG</span> collection Nature Oracle !
                        </h2>
                        <p className="about-text">
                        •	Minting on November 30. <span className="about-emphasis">Sold out in 12 hours!</span>{"\n"}
                        {/* •	<span className="about-emphasis">Listing on OperaHouse, NFTKEY and PaintSwap</span>{"\n"}
                        •	<span className="about-emphasis">Gallery and Oracle reading </span>opened on our website{"\n"} */}
                        •	3% royalty from secondary market sales going to a <span className="about-emphasis">treasury</span>wallet 
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={3}>
                    <Image src="img/moonhalf_blue.png" preview={false} style={{maxWidth: "150px"}}/>
                    </Col>
                    <Col md={18} sm={21}>
                        <h2 style={{textAlign: "left"}}>
                        January-February 2022
                        </h2>
                        <p className="about-text">
                        {/* •	<span className="about-emphasis">3 Nature Oracle NFTs</span> used for social media promotion.{"\n"} */}
                        •	<span className="about-emphasis">Live access</span> to interactive card readings on our website{"\n"} 
                        {/* •	<span className="about-emphasis">Interactive raffles:</span> 2 Nature Oracle NFTs airdropped to winning token holders as a reward for holding their NFTs{"\n"}   */}
                        •	All of the 13 pre-minted NFTs given back to the community through raffles, games and aidrops.{"\n"}
                        •	Nature Oracle holders were able to claim <span className="about-emphasis">$OATH</span> allocations with REAPER/Bytes Masons.{"\n"} 
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={3}>
                    <Image src="img/moonfull_blue.png" preview={false} style={{maxWidth: "150px"}}/>
                    </Col>
                    <Col md={18} sm={21}>
                        <h2 style={{textAlign: "left"}}>
                        March 18 2022 - Witches Oracle Mint!
                        </h2>
                        <p className="about-text">
                        •	Release of Zoey's new art-based NFT project, <span className="about-emphasis-witches">Witches Oracle</span>. <span className="about-emphasis-witches">Sold out in 1 hour!</span>{"\n"}
                        •	3% royalty from secondary market sales going to a <span className="about-emphasis">treasury</span> wallet{"\n"} 
                        •	1 Tribute card auctioned at the <span className="about-emphasis">FantomArtCollective</span> to raise money for the <span className="about-emphasis">Malala Fund for girls' education</span>{"\n"}                        
                        {/* •	Find all the details about the launch in our <a href="https://medium.com/@NatureOracle/witches-oracle-nft-official-release-announcement-on-fantom-chain-bd49d60a2c36" target="_blank">Medium article</a>{"\n"}
                        •	Listing of Witches Oracle on OperaHouse, NFTKEY and PaintSwap{"\n"}
                        •	1 Tribute card to be auctioned at the <span className="about-emphasis">FantomArtCollective</span> to raise money for the <span className="about-emphasis">Malala Fund for girls' education</span>{"\n"}
                        •	1 Rare Signed card to be auctioned to raise funds for our treasury wallet */}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={3}>
                    <Image src="img/moonhalf_blue.png" preview={false} style={{maxWidth: "150px"}}/>
                    </Col>
                    <Col md={18} sm={21}>
                        <h2 style={{textAlign: "left"}}>
                        March 2022: Raffle + Revenue redistribution begins!
                        </h2>
                        <p className="about-text">
                        •	Bi-monthly Raffles opens March 30 for both <span className="about-emphasis">Nature + Witches Oracle</span>{"\n"}
                        •	The prize money comes from our treasury wallet, which is funded by 3% royalty on all secondary sales and part of our mint proceeds. These 2 sources of revenue will allow us to fund the raffles for the long term.{"\n"}
                        •	We are the first art based collection to offer revenue distribution on <span className="about-emphasis">#FTM</span>. For us, it's the next step in the NFT world: offering utility to holders.{"\n"}
                        {/* •	Begin the development of <span className="about-emphasis">Collectors' tokens</span>{"\n"}
                        •	Organize periodic <span className="about-emphasis">raffles &amp; games</span> to redistribute the money from the DAO fund{"\n"}
                        •	Develop opportunities for staking &amp; passive income for the OG collection NatureOracle and WitchesOracle{"\n"}
                        •	Build a following on Avalanche and Ethereum, in order to expose art to new buyers and enhance Nature Oracle and Witches Oracle's recognition{"\n"} */}
                        </p>
                        <h2 style={{textAlign: "left"}}>
                        <span className="about-emphasis">Benefits of the raffle system</span>
                        </h2>
                        <p className="about-text">
                        •	Build community{"\n"}
                        •	Offers passive income to our holders{"\n"}
                        •	Attracts new buyers{"\n"}
                        •	Allows holders to own the art as it gains in value and, to receive revenue for holding it.{"\n"}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={3}>
                    <Image src="img/moonfull_blue.png" preview={false} style={{maxWidth: "150px"}}/>
                    </Col>
                    <Col md={18} sm={21}>
                        <h2 style={{textAlign: "left"}}>
                        May 2022: BitWitches:Banner free mint!
                        </h2>
                        <p className="about-text">
                        •	Exclusive <a href="/surprise">FREE Mint</a> of BitWitches:Banners - a surprise NFT project by Zoey - for existing holders of <span className="about-emphasis">NatureOracle</span> or <span className="about-emphasis-witches">WitchesOracle</span> NFTs!{"\n"}
                        •	Several pre-minted banners to use for promotion and brand building to increase awareness and bring new collectors{"\n"}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={0}>
                    </Col>
                    <Col md={18} sm={24}>
                    <Image style={{maxWidth: "1200px"}} src="img/roadmap_v2.png" preview={false}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} sm={3}>
                    <Image src="img/moonhalf_blue.png" preview={false} style={{maxWidth: "150px"}}/>
                    </Col>
                    <Col md={18} sm={21}>
                        <h2 style={{textAlign: "left"}}>
                        Exciting next steps in 2022
                        </h2>
                        <p className="about-text">
                        •	1/1 raffle every month: <span className="about-emphasis">Zoey</span> will create a special 1/1 in a separate special-edition collection regularly{"\n"}
                        {/* •	Explore NFT bridging opportunities with <span className="about-emphasis">MultiChain</span> to tease more buyers towards <span className="about-emphasis">Fantom</span> and support the long-term value of our collections{"\n"} */}
                        •	Free 1 for 1 airdrop from Zoey's 3rd collection to all Oracle holders{"\n"}
                        {"\n"}
                        •	Fun &amp; exclusive community building events: live tarot readings, live games on Discord with prizes, special physical giveaway, 8bit-Metaverse exhibit gallery{"\n"}
                        {"\n"}
                        •	Collabs with <span className="about-emphasis">#FTM</span> artists from our amazing community{"\n"}
                        •	Continuous brand building and marketing across social medias for our unique universe{"\n"}
                        •	Marketing and networking on other chains + option to bridge to other chains in Q3 2022.{"\n"}                        
                        </p>
                    </Col>
                </Row>
                <Row>
                <Col md={24} sm={24}>
                    <p className="timeline-text">
                    Follow the latest updates to our roadmap on <a href="https://medium.com/@NatureOracle" target="_blank">Medium</a> or on our <a href="https://discord.gg/duTABXQuJC" target="_blank">Discord server</a>.
                    </p>
                </Col>
                </Row>
            </div>
        </div>
    </div>
  );
}
