import { Card, Input, Tabs, Button, Tooltip } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from "react";
import { notification } from "antd";
import { useContractLoader } from "../hooks";
import TweenOne from 'rc-tween-one';
import { useParams } from "react-router-dom";
import { Row, Col, Image } from 'antd';
// to change
import title_glimpse from "./img/SVG/title_reading.svg";
import Citation from "./Citation.jsx";
import { DEFAULT_CONTRACT_NAME } from "../constants";
import { dictNatureOraclePrediction } from './listnft';

const { TabPane } = Tabs;

const DEBUG = false;

// async function getNFT({contract, provider, tokenId}) {
//   const metadataURI = await contract.tokenURI(tokenId);
//   if (DEBUG) console.log('metadata uri: ', metadataURI);
  
//   const metadata = await fetchIPFSJSON(metadataURI);
//   if (DEBUG) console.log('metadata: ', metadata)

//   if (metadata.image) {
//     metadata.image = makeGatewayURL(metadata.image);
//   }

//   console.log(metadata.card_fullname);

//   if (metadata.card_fullname) {
//       metadata.rarity = dictNatureOraclePrediction[metadata.card_fullname].rarity;
//       metadata.name = dictNatureOraclePrediction[metadata.card_fullname].name;
//       metadata.prediction = dictNatureOraclePrediction[metadata.card_fullname].prediction;
//   }

//   return metadata;
// }

function romanize(num) {
    var lookup = {M:1000,CM:900,D:500,CD:400,C:100,XC:90,L:50,XL:40,X:10,IX:9,V:5,IV:4,I:1},roman = '',i;
    for ( i in lookup ) {
      while ( num >= lookup[i] ) {
        roman += i;
        num -= lookup[i];
      }
    }
    return roman;
}
 
function maskShowroom(title, prediction) {
    return (
        <div>
            <p className="nftreading-mask-title">
              "{title}"
            </p>
            <p className="nftreading-mask-description">
              {prediction}
            </p>
        </div>
    )
}

{/* <TweenOne
          animation={{
            y: '+=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="down"
          key="icon"
        >
          <DownOutlined className="down"/>
        </TweenOne> */}

function drawnCard(tokenId, classreading) {
  DEBUG && console.log(tokenId);
  let cardData = dictNatureOraclePrediction[tokenId];
  let imgsrc = `deck/${tokenId}.jpg`;
  return (
    <Image className={classreading} src={imgsrc} preview={{ mask: maskShowroom(cardData.name, cardData.prediction), maskClassName: "nftreading-mask-style"}}/>
  );
}

function getRandomOracleCardName(listselected) {
  console.log('list', listselected)
  var keys = Object.keys(dictNatureOraclePrediction);
  // Select a random key
  var key = keys[keys.length * Math.random() << 0];
  while (listselected.includes(key)){
    key = keys[keys.length * Math.random() << 0];
  }
  console.log(key);
  //return '0Being_Protected'
  return key;
}

// function drawnCard(tokenId) {
//   DEBUG && console.log(tokenId);
//   let cardData = dictNatureOraclePrediction[tokenId];
//   let imgsrc = `deck/${tokenId}.jpg`;
//   return (
//     <TweenOne
//       animation={{
//         x: '+500',
//         yoyo: false,
//         repeat: 0,
//         duration: 1000,
//       }}
//       key="icon"
//     >
//       <Image className=".nftreading-img" src={imgsrc} preview={{ mask: maskShowroom(cardData.name, cardData.prediction), maskClassName: "nftreading-mask-style"}}/>
//     </TweenOne>    
//   );
// }

// function drawnCard(tokenId) {
//   DEBUG && console.log(tokenId);
//   let cardData = dictNatureOraclePrediction[tokenId];
//   let imgsrc = `deck/${tokenId}.jpg`;
//   let animation = [
//     { x: '+500' },
//   ];
//   return (
//     <TweenOne
//       animation={animation}
//       yoyo = {false}
//       repeat = {0}
//       duration = {1000}
//       key="icon"
//     >
//       <Image className="nftreading-img" src={imgsrc} preview={{ mask: maskShowroom(cardData.name, cardData.prediction), maskClassName: "nftreading-mask-style"}}/>
//     </TweenOne>    
//   );
// }

export default function NFTReadings({
  nftbalance,
  nftbalancewito,
  provider,
  address,
  web3Modal
}) {
  const contracts = useContractLoader(provider);
  let contract;
  contract = contracts ? contracts[DEFAULT_CONTRACT_NAME] : "";
  //console.log('nft readings address', address);
  //const address = contract ? contract.address : "";
  const nftBalance = nftbalance ? nftbalance.toNumber() : 0;
  const nftBalanceWito = nftbalancewito ? nftbalancewito.toNumber() : 0;

  const [notificationFired, setNotificationFired] = useState(false);

  if (web3Modal) {
    if (!web3Modal.cachedProvider) {
       // no wallet is connected
       if (!notificationFired) {
          setNotificationFired(true);
          notification.info({
            message: "Please connect Wallet",
            description: "A Wallet with at least 1 NATO is required to access advanced reading spreads",
            duration: 60,
            placement: "bottomRight",
          });
        }
    }
  }

  const disableTab2 = ((nftBalance + nftBalanceWito) < 1);
  const disableTab3 = ((nftBalance + nftBalanceWito) < 3);
  const disableTab4 = ((nftBalance + nftBalanceWito) < 5);

  let tokenView = "";

  const placeholderCard = ( 
    <Image className="nftreading-img" src={"img/reading_background.jpg"} preview={false}/>
  )

  const placeholderCardSmall = ( 
    <Image className="nftreading-img-tab3" src={"img/reading_background.jpg"} preview={false}/>
  )

  // STATES
    
  ///////////////////////////////////////////////////////////////////
  // TAB 1 - Draw of the day related states
  const [stateTab1, setStateTab1] = useState(0);
  const [drawnCardTab1, setDrawnCardTab1] = useState(placeholderCard);
  const [resetDisabledTab1, setResetDisabledTab1] = useState(true);
  const [tooltipMessageTab1, setTooltipMessageTab1] = useState("Click to draw a card");

  const drawCardTab1 = () => {
    if (stateTab1 == 1) {
      return;
    }
    let tokenId = getRandomOracleCardName([]);
    setStateTab1(1);
    tokenView = drawnCard(tokenId, "nftreading-img");
    setDrawnCardTab1(tokenView);
    setResetDisabledTab1(false);
    setTooltipMessageTab1("Click Reset to draw again");
    DEBUG && console.log(tokenView);
  }

  const resetTab1 = () => {
    setStateTab1(0);
    setDrawnCardTab1(placeholderCard);
    setResetDisabledTab1(true);
  }
  
  const resetButtonTab1 = (
    <Button className="button-minting" type="primary" disabled={resetDisabledTab1} onClick={resetTab1}>
      Reset Draw
    </Button>
  )

  ///////////////////////////////////////////////////////////////////
  // TAB 2 - Draw of the day related states
  const [stateTab2, setStateTab2] = useState(0);
  const [drawnCardTab2, setDrawnCardTab2] = useState(placeholderCard);
  const [resetDisabledTab2, setResetDisabledTab2] = useState(true);
  const [tooltipMessageTab2, setTooltipMessageTab2] = useState("Enter your question first");
  const [readingQuestionTab2, setReadingQuestionTab2] = useState("");
  const [instructionsStep1Tab2, setInstructionsStep1Tab2] = useState("");
  const [errorMessageTab2, setErrorMessageTab2] = useState("");

  const questionChangedTab2 = e => {
    if (!e) {
      return;
    }
    console.log(e.target.value);
    setReadingQuestionTab2(e.target.value);
    if ((e.target.value.length < 3) || !(e.target.value.includes("?"))) {
      setErrorMessageTab2("Please enter an actual question 😄 (ie. question mark and at least 3 letters!!)");
      return ;
    }
    setErrorMessageTab2("");
    setStateTab2(1); // go to state 1 - breath
    setInstructionsStep1Tab2("•	Step 2 - Take 3 deep breaths");
    setTooltipMessageTab2("Breath...");
    setTimeout(enableDrawCardTab2, 6000);
  }

  const enableDrawCardTab2 = () => {
    setStateTab2(2); // go to state 2 - draw a card
    setInstructionsStep1Tab2("•	Step 2 - Take 3 deep breaths...\n•	Step 3 - Draw your card by clicking the deck");
    setTooltipMessageTab2("Draw your card for an answer");
  }

  const drawCardTab2 = () => {
    if (stateTab2 != 2) {
      return;
    }
    let tokenId = getRandomOracleCardName([]);
    setStateTab2(3); // set to state 2 - card drawn, reset available
    tokenView = drawnCard(tokenId, "nftreading-img");
    setDrawnCardTab2(tokenView);
    setResetDisabledTab2(false);
    setTooltipMessageTab2("Click Reset to restart");
    DEBUG && console.log(tokenView);
  }

  const resetTab2 = () => {
    setStateTab2(0);
    setDrawnCardTab2(placeholderCard);
    setResetDisabledTab2(true);
    setTooltipMessageTab2("Enter your question first");
    setInstructionsStep1Tab2("");
    setReadingQuestionTab2("");
    setErrorMessageTab2("");
  }
  
  const resetButtonTab2 = (
    <Button className="button-minting" type="primary" disabled={resetDisabledTab2} onClick={resetTab2}>
      Reset Draw
    </Button>
  )

  ///////////////////////////////////////////////////////////////////
  // TAB 3 - Draw of the day related states
  const instructionTab3Step1 = "•	Step 1 - Think about a question and type it in\n";
  const instructionTab3Step2 = "•	Step 2 - Take 3 deep breaths...\n";
  const instructionTab3Step3 = "•	Step 3 - Draw a card for the PAST\n";
  const instructionTab3Step4 = "•	Step 4 - Draw a card for the PRESENT\n";
  const instructionTab3Step5 = "•	Step 5 - Draw a card for the FUTURE\n";
  const instructionTab3Step6 = "•	Step 6 - Study the meaning of each card and reflect on how it can help you make sense of the situation and take perspective on your question.\n";

  const [stateTab3, setStateTab3] = useState(0);
  const [drawnCard1Tab3, setDrawnCard1Tab3] = useState(placeholderCardSmall);
  const [drawnCard2Tab3, setDrawnCard2Tab3] = useState(placeholderCardSmall);
  const [drawnCard3Tab3, setDrawnCard3Tab3] = useState(placeholderCardSmall);
  const [resetDisabledTab3, setResetDisabledTab3] = useState(true);
  const [tooltipMessageTab3, setTooltipMessageTab3] = useState("Enter your question first");
  const [readingQuestionTab3, setReadingQuestionTab3] = useState("");
  const [instructionsStep1Tab3, setInstructionsStep1Tab3] = useState(instructionTab3Step1 + "\n\n\n\n\n");
  const [listDrawnCardsTab3, setListDrawnCardsTab3] = useState([]);
  const [errorMessageTab3, setErrorMessageTab3] = useState("");

  const questionChangedTab3 = e => {
    if (!e) {
      return;
    }
    console.log(e.target.value);
    setReadingQuestionTab3(e.target.value);
    if ((e.target.value.length < 3) || !(e.target.value.includes("?"))) {
      setErrorMessageTab3("Please enter an actual question 😄 (ie. question mark and at least 3 letters!!)");
      return ;
    }
    setErrorMessageTab3("");
    setStateTab3(1); // go to state 1 - breath
    setInstructionsStep1Tab3(instructionTab3Step1 + instructionTab3Step2 + "\n\n\n\n");
    setTooltipMessageTab3("Breath...");
    setTimeout(enableDrawCardTab3, 6000);
  }

  const enableDrawCardTab3 = () => {
    setStateTab3(2); // go to state 2 - draw the first card
    setInstructionsStep1Tab3(instructionTab3Step1 + instructionTab3Step2 + instructionTab3Step3 + "\n\n\n");
    setTooltipMessageTab3("Draw the card for PAST");
  }

  const drawCardTab3 = () => {
    if ((stateTab3 < 1) && (stateTab3 < 5)) {
      return;
    }
    let tokenId = getRandomOracleCardName(listDrawnCardsTab3);
    setListDrawnCardsTab3([...listDrawnCardsTab3, tokenId]);
    tokenView = drawnCard(tokenId, "nftreading-img-tab3");
    if (stateTab3 == 2) {
      setDrawnCard1Tab3(tokenView);
      setTooltipMessageTab3("Draw the card for PRESENT");
      setInstructionsStep1Tab3(instructionTab3Step1 + instructionTab3Step2 + instructionTab3Step3 + instructionTab3Step4 + "\n\n");
    }
    else if (stateTab3 == 3) {
      setDrawnCard2Tab3(tokenView);
      setTooltipMessageTab3("Draw the card for FUTURE");
      setInstructionsStep1Tab3(instructionTab3Step1 + instructionTab3Step2 + instructionTab3Step3 + instructionTab3Step4 + instructionTab3Step5 + "\n");
    }
    else if (stateTab3 == 4) {
      setDrawnCard3Tab3(tokenView);
      setTooltipMessageTab3("Click Reset to restart");
      setResetDisabledTab3(false);
      setInstructionsStep1Tab3(instructionTab3Step1 + instructionTab3Step2 + instructionTab3Step3 + instructionTab3Step4 + instructionTab3Step5 + instructionTab3Step6);
    }

    setStateTab3(stateTab3 + 1); // set to state 2 - card drawn, reset available
    DEBUG && console.log(tokenView);
  }

  const resetTab3 = () => {
    setStateTab3(0);
    setDrawnCard1Tab3(placeholderCardSmall);
    setDrawnCard2Tab3(placeholderCardSmall);
    setDrawnCard3Tab3(placeholderCardSmall);
    setResetDisabledTab3(true);
    setTooltipMessageTab3("Enter your question first");
    setInstructionsStep1Tab3(instructionTab3Step1 + "\n\n\n\n\n");
    setReadingQuestionTab3("");
    setErrorMessageTab3("");
  }
  
  const resetButtonTab3 = (
    <Button className="button-minting" type="primary" disabled={resetDisabledTab3} onClick={resetTab3}>
      Reset Draw
    </Button>
  )


  ///////////////////////////////////////////////////////////////////
  // TAB 4 - Celtic Draw - Draw of the day related states
  const instructionTab4Step1 = "•	Step 1 - Think about a question and type it in\n";
  const instructionTab4Step2 = "•	Step 2 - Take 3 deep breaths...\n";
  const instructionTab4Step3 = "•	Step 3 - Draw the card for the current situation, the present\n";
  const instructionTab4Step4 = "•	Step 4 - Draw the card for the challenges, the past\n";
  const instructionTab4Step5 = "•	Step 5 - Draw the card for the guiding influence, the future\n";
  const instructionTab4Step6 = "•	Step 6 - Draw the card for the focus and reason behind the situation\n";
  const instructionTab4Step7 = "•	Step 7 - Draw the final card about the potential outcome of the situation\n";
  const instructionTab4Step8 = "•	Step 8 - Study the meaning of each card and reflect on how they can help you make sense of the situation and take perspective on your question.\n";

  const [stateTab4, setStateTab4] = useState(0);
  const [drawnCard1Tab4, setDrawnCard1Tab4] = useState(placeholderCardSmall);
  const [drawnCard2Tab4, setDrawnCard2Tab4] = useState(placeholderCardSmall);
  const [drawnCard3Tab4, setDrawnCard3Tab4] = useState(placeholderCardSmall);
  const [drawnCard4Tab4, setDrawnCard4Tab4] = useState(placeholderCardSmall);
  const [drawnCard5Tab4, setDrawnCard5Tab4] = useState(placeholderCardSmall);
  const [resetDisabledTab4, setResetDisabledTab4] = useState(true);
  const [tooltipMessageTab4, setTooltipMessageTab4] = useState("Enter your question first");
  const [readingQuestionTab4, setReadingQuestionTab4] = useState("");
  const [instructionsStep1Tab4, setInstructionsStep1Tab4] = useState(instructionTab4Step1);
  const [listDrawnCardsTab4, setListDrawnCardsTab4] = useState([]);
  const [errorMessageTab4, setErrorMessageTab4] = useState("");

  const questionChangedTab4 = e => {
    if (!e) {
      return;
    }
    console.log(e.target.value);
    setReadingQuestionTab4(e.target.value);
    if ((e.target.value.length < 3) || !(e.target.value.includes("?"))) {
      setErrorMessageTab4("Please enter an actual question 😄 (ie. question mark and at least 3 letters!!)");
      return ;
    }
    setErrorMessageTab4("");
    setStateTab4(1); // go to state 1 - breath
    setInstructionsStep1Tab4(instructionTab4Step1 + instructionTab4Step2);
    setTooltipMessageTab4("Breath...");
    setTimeout(enableDrawCardTab4, 6000);
  }

  const enableDrawCardTab4 = () => {
    setStateTab4(2); // go to state 2 - draw the first card
    setInstructionsStep1Tab4(instructionTab4Step1 + instructionTab4Step2 + instructionTab4Step3 + "\n\n\n\n\n");
    setTooltipMessageTab4("Draw the card for the current situation");
  }

  const drawCardTab4 = () => {
    if ((stateTab4 < 1) && (stateTab4 < 7)) {
      return;
    }
    let tokenId = getRandomOracleCardName(listDrawnCardsTab4);
    setListDrawnCardsTab4([...listDrawnCardsTab4, tokenId]);
    tokenView = drawnCard(tokenId, "nftreading-img-tab3");
    if (stateTab4 == 2) {
      setDrawnCard1Tab4(tokenView);
      setTooltipMessageTab4("Draw the card for challenge/past");
      setInstructionsStep1Tab4(instructionTab4Step1 + instructionTab4Step2 + instructionTab4Step3 + instructionTab4Step4 + "\n\n");
    }
    else if (stateTab4 == 3) {
      setDrawnCard2Tab4(tokenView);
      setTooltipMessageTab4("Draw the card for guidance/future");
      setInstructionsStep1Tab4(instructionTab4Step1 + instructionTab4Step2 + instructionTab4Step3 + instructionTab4Step4 + instructionTab4Step5 + "\n");
    }
    else if (stateTab4 == 4) {
      setDrawnCard3Tab4(tokenView);
      setTooltipMessageTab4("Draw the card for focus/reason");
      setInstructionsStep1Tab4(instructionTab4Step1 + instructionTab4Step2 + instructionTab4Step3 + instructionTab4Step4 + instructionTab4Step5 + instructionTab4Step6);
    }
    else if (stateTab4 == 5) {
      setDrawnCard4Tab4(tokenView);
      setTooltipMessageTab4("Draw the card for the outcome/potential");
      setInstructionsStep1Tab4(instructionTab4Step1 + instructionTab4Step2 + instructionTab4Step3 + instructionTab4Step4 + instructionTab4Step5 + instructionTab4Step6 + instructionTab4Step7);
    }
    else if (stateTab4 == 6) {
      setDrawnCard5Tab4(tokenView);
      setTooltipMessageTab4("Click Reset to restart");
      setResetDisabledTab4(false);
      setInstructionsStep1Tab4(instructionTab4Step1 + instructionTab4Step2 + instructionTab4Step3 + instructionTab4Step4 + instructionTab4Step5 + instructionTab4Step6 + instructionTab4Step7 + instructionTab4Step8);
    }

    setStateTab4(stateTab4 + 1);
    DEBUG && console.log(tokenView);
  }

  const resetTab4 = () => {
    setStateTab4(0);
    setDrawnCard1Tab4(placeholderCardSmall);
    setDrawnCard2Tab4(placeholderCardSmall);
    setDrawnCard3Tab4(placeholderCardSmall);
    setDrawnCard4Tab4(placeholderCardSmall);
    setDrawnCard5Tab4(placeholderCardSmall);
    setResetDisabledTab4(true);
    setTooltipMessageTab4("Enter your question first");
    setInstructionsStep1Tab4(instructionTab4Step1);
    setReadingQuestionTab4("");
    setErrorMessageTab4("");
  }
  
  const resetButtonTab4 = (
    <Button className="button-minting" type="primary" disabled={resetDisabledTab4} onClick={resetTab4}>
      Reset Draw
    </Button>
  )

  return (
    <section id="nft-readings">
        <div className="nftcontainer">
            <div id="nft-readings-body">
                <img className="nft-reading-title" src={title_glimpse}/>    
                <p className="timeline-text">
                You unlock the other spreads based on the number of <span className="emphasis">Nature Oracle &amp; Witches Oracle NFTs</span> found in your connected wallet. Learn more in the <a href="https://medium.com/@NatureOracle/the-nature-oracle-readings-app-c8bbea93b2e0" target="_blank">Readings article on Medium.</a>
                </p>
                <Tabs defaultActiveKey="1" centered>
                  <TabPane key="1" tab="Draw of the day" className="nftreading-tab">
                    <Row>
                      <Col md={12} sm={24} className="nftreading-instructions">
                      Draw your <span className="about-emphasis">Card of the Day</span> by clicking on the deck below
                      </Col>
                      <Col md={12} sm={24}>
                        {resetButtonTab1}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} sm={24}>
                        <Tooltip title={tooltipMessageTab1} color={'#de767b'} key={'#de767b'} placement="right">
                          <Image className="nftreading-img" src={"img/card_back_3.jpg"} preview={false} onClick={drawCardTab1}/>
                        </Tooltip>
                      </Col>
                      <Col md={12} sm={24}>
                        {drawnCardTab1}
                      </Col>
                    </Row>
                    {/* <div class="reading-scene">
                      <div class="reading-card">
                        <Image className="reading-card-face reading-card-face--front" src={"img/card_back_1.jpg"} preview={false} style={{maxHeight: "400px"}} onClick={flipCard}/>
                        <Image className="reading-card-face reading-card-face--back" src={"deck/0Being_Protected.jpg"} preview={false} style={{maxHeight: "400px"}} onClick={flipCard}/>
                      </div>
                    </div> */}
                  </TabPane>
                  <TabPane key="2" disabled={disableTab2} tab="1 Card 1 Question">
                    <Row>
                      <Col md={12} sm={24} className="nftreading-instructions">
                      Perform a <span className="about-emphasis">single card reading</span>
                      <Tooltip title="Typing fully the question does not matter as much as focussing your mind on the issue or situation" color={'#de767b'} key={'#de767b'} placement="top">
                        <p className="nftreading-steps">
                          •	Step 1 - Think of a question and type it in. Try to formulate it in an open-ended way{"\n"}
                          {instructionsStep1Tab2}                        
                        </p>
                      </Tooltip>
                      </Col>
                      <Col md={12} sm={24}>
                        {resetButtonTab2}
                        <Input placeholder="Type your question here" value={readingQuestionTab2} onChange={questionChangedTab2} style={{marginTop: "10px"}}/>
                        {errorMessageTab2}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} sm={24}>
                        <Tooltip title={tooltipMessageTab2} color={'#de767b'} key={'#de767b'} placement="right">
                          <Image className="nftreading-img" src={"img/card_back_3.jpg"} preview={false} onClick={drawCardTab2}/>
                        </Tooltip>
                      </Col>
                      <Col md={12} sm={24}>
                        {drawnCardTab2}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane key="3" disabled={disableTab3} tab="3 Cards Spread">
                    <Row>
                      <Col md={12} sm={24} className="nftreading-instructions">
                      Perform a classic <span className="about-emphasis">3-card Oracles spread</span>
                      </Col>
                      <Col md={12} sm={24}>
                        {resetButtonTab3}
                        <Input placeholder="Type your question here" value={readingQuestionTab3} onChange={questionChangedTab3} style={{marginTop: "10px"}}/>
                        {errorMessageTab3}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={24} sm={24} className="nftreading-instructions">
                      <Tooltip title="Typing fully the question does not matter as much as focussing your mind on the issue or situation" color={'#de767b'} key={'#de767b'} placement="topLeft">
                      <p className="nftreading-steps">
                        {instructionsStep1Tab3}                        
                      </p>
                      </Tooltip>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={24}>
                        <Tooltip title={tooltipMessageTab3} color={'#de767b'} key={'#de767b'} placement="right">
                          <Image className="nftreading-img-tab3" src={"img/card_back_3.jpg"} preview={false} onClick={drawCardTab3}/>
                        </Tooltip>
                      </Col>
                      <Col md={6} sm={24}>
                        {drawnCard1Tab3}
                      </Col>
                      <Col md={6} sm={24}>
                        {drawnCard2Tab3}
                      </Col>
                      <Col md={6} sm={24}>
                        {drawnCard3Tab3}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={24}>
                      </Col>
                      <Col md={6} sm={24}>
                        PAST
                      </Col>
                      <Col md={6} sm={24}>
                        PRESENT
                      </Col>
                      <Col md={6} sm={24}>
                        FUTURE
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane key="4" disabled={disableTab4} tab="Simplified Celtic Cross Spread">
                  <Row>
                      <Col md={12} sm={24} className="nftreading-instructions">
                      Perform a simplified <span className="about-emphasis">5-card Celtic Cross spread</span>
                      </Col>
                      <Col md={12} sm={24}>
                        {resetButtonTab4}
                        <Input placeholder="Type your question here" value={readingQuestionTab4} onChange={questionChangedTab4} style={{marginTop: "10px"}}/>
                        {errorMessageTab4}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} sm={24}>
                        <Tooltip title="Typing fully the question does not matter as much as focussing your mind on the issue or situation" color={'#de767b'} key={'#de767b'} placement="topLeft">
                        <p className="nftreading-steps">
                          {instructionsStep1Tab4}                        
                        </p>
                        </Tooltip>
                      </Col>
                      <Col md={6} sm={24}>
                        {/* Top row 1 single card */}
                        {drawnCard5Tab4}
                      </Col>
                      <Col md={6} sm={24}>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={24}>
                      </Col>
                      <Col md={6} sm={24}>
                      </Col>
                      <Col md={6} sm={24}>
                        5. OUTCOME/POTENTIAL
                      </Col>
                      <Col md={6} sm={24}>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={24}>
                        <Tooltip title={tooltipMessageTab4} color={'#de767b'} key={'#de767b'} placement="right">
                          <Image className="nftreading-img-tab3" src={"img/card_back_3.jpg"} preview={false} onClick={drawCardTab4}/>
                        </Tooltip>
                      </Col>
                      <Col md={6} sm={24}>
                        {drawnCard2Tab4}
                      </Col>
                      <Col md={6} sm={24}>
                        {drawnCard1Tab4}
                      </Col>
                      <Col md={6} sm={24}>
                        {drawnCard3Tab4}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={24}>
                      </Col>
                      <Col md={6} sm={24}>
                        2. CHALLENGE/PAST
                      </Col>
                      <Col md={6} sm={24}>
                        1. SITUATION/PRESENT
                      </Col>
                      <Col md={6} sm={24}>
                        3. GUIDANCE/FUTURE
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={24}>
                      </Col>
                      <Col md={6} sm={24}>
                      </Col>
                      <Col md={6} sm={24}>
                        {/* Bottom row 1 single card */}
                        {drawnCard4Tab4}
                      </Col>
                      <Col md={6} sm={24}>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} sm={24}>
                      </Col>
                      <Col md={6} sm={24}>
                      </Col>
                      <Col md={6} sm={24}>
                        4. FOCUS/REASON
                      </Col>
                      <Col md={6} sm={24}>
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
                {/* <Row>
                    <Col md={24} sm={24}>
                        <p>
                        To access your reading, enter your Nature Oracle Card # or pick one randomly (1-143): {nftBalance}
                        <InputNumber value={selectedToken} onChange={tokenIdChanged}/>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={24} sm={24}>
                        {loading && <LoadingOutlined/>}
                        {errorView}
                    </Col>
                </Row>
                {loading ? <Image className="nftgallery-img" src={"img/card_back_1.jpg"} preview={false} style={{maxHeight: "800px"}}/> : tokenView} */}
            </div>
        </div>
    </section>
  );
}
