import React from 'react';
import { Row, Col, Image, Typography } from 'antd';
import title_handmade from "./img/SVG/title_handmade.svg";
import slider from "./img/slider_5.gif";
import slider_witches from "./img/slider_witches.gif";
import slider_banners from "./img/banners.gif";
import Citation from "./Citation.jsx";

const { Text } = Typography;

export default function About(props) {

    return (
        <div id="about" className="container">
          <Image src={title_handmade} height={"170px"} preview={false}/>
          <div>
            <Citation 
                citation="Nature is everything, and we are part of nature."
                author=""
            />
            <Row>
                <Col md={8} sm={12}>
                <p className="timeline-text">
                    <span className="about-emphasis">143</span> Nature Oracle cards only.
                </p>
                <p className="timeline-text">
                    Immerse yourself in the magic of Nature...
                </p>
                </Col>
                <Col md={8} sm={0}>
                <p className="timeline-text">
                    <span className="about-emphasis-witches">333</span> BitWitches Oracle Banners.
                </p>
                <p className="timeline-text">
                    Face the all-accepting Goddess...
                </p>
                </Col>
                <Col md={8} sm={12}>
                <p className="timeline-text">
                    <span className="about-emphasis">168</span> Witches Oracle cards only.
                </p>
                <p className="timeline-text">
                    Enter and Meet our Witches, Discover their lore
                </p>
                </Col>
            </Row>            
            <Row>
                <Col md={8} sm={12}>
                <a href="/nature"><Image className="nftgallery-img" src={slider} preview={false}/></a>
                </Col>
                <Col md={8} sm={0}>
                <a href="/surprise"><Image className="nftgallery-img" src={slider_banners} preview={false}/></a>
                </Col>
                <Col md={8} sm={12}>
                <a href="/witches"><Image className="nftgallery-img" src={slider_witches} preview={false}/></a>
                </Col>
            </Row>
            <Row>
                <Col md={8} sm={12}>
                <a href="https://nftkey.app/collections/natureoracle/" target="_blank"><Image className="paintswap-icon" height={"70px"} src={"img/nftkey.svg"} preview={false}/></a>
                </Col>
                <Col md={8} sm={0}>
                <a href="https://nftkey.app/collections/bitwitchesoraclebanners/" target="_blank"><Image className="paintswap-icon" height={"70px"} src={"img/nftkey.svg"} preview={false}/></a>
                </Col>
                <Col md={8} sm={12}>
                <a href="https://nftkey.app/collections/witchesoracle/" target="_blank"><Image className="paintswap-icon" height={"70px"} src={"img/nftkey.svg"} preview={false}/></a>
                </Col>
            </Row>
          </div>
        </div>
    );
  }
  