import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import ScrollElement from 'rc-scroll-anim/lib/ScrollElement';
import { Parallax } from 'rc-scroll-anim';
import TweenOne from 'rc-tween-one';
import { DownOutlined } from '@ant-design/icons';
import { Statistic, Image } from "antd";
import QueueAnim from 'rc-queue-anim';
import ConnectWallet from "./ConnectWallet.jsx";
import MintButton from "./MintButton.jsx";
import { MAX_MINTABLE_NFT } from "../constants";

function typeFunc(a) {
  if (a.key === 'line') {
    return 'right';
  } else if (a.key === 'button') {
    return 'bottom';
  }
  return 'left';
}

// DEBUG FLAG - set to false in prod
const DEBUG = false;

const { Countdown } = Statistic;

//const deadline = new Date(Date.UTC(2021, 11, 1, 0, 20, 0, 0));
const deadline = new Date(Date.UTC(2021, 11, 1, 3, 0, 0, 0));

const refreshOnFinish = () => {
  setTimeout(() => {
    window.location.reload();
  }, 1);
};

export default function NatureBanner({ 
  onEnterChange,
  gasPrice,
  signer,
  provider,
  address,
  blockExplorer,
  totalSupply,
  price,
  balance,
  web3Modal,
  loadWeb3Modal,
  logoutOfWeb3Modal
  }) {

  const [isCountdownFinished, setIsCountdownFinished] = useState(true);
  
  //const mintRemaining = totalSupply ? (MAX_MINTABLE_NFT - totalSupply.toNumber()) > 0 : false;
  const mintRemaining = true;

  if (DEBUG) console.log("provider", provider.connection.url);
  if (DEBUG) console.log("address", address);
  
  // startingstatus = isCountdownFinished ? startingstatus : 'countingdown';
  // if (DEBUG) console.log("startingstatus", startingstatus);
  // //const [status, setStatus] = useState(startingstatus);
  // const [status, setStatus] = useState(isCountdownFinished ? 'walletrequired' : 'countingdown');

  // if (web3Modal) {
  //   if (web3Modal.cachedProvider) {
  //     // a wallet is connected
  //     startingstatus = 'readytomint';
  //     setStatus(isCountdownFinished ? 'readytomint' : 'countingdown');
  //   }
  // }

  const countdown = (
    <Countdown title="COUNTDOWN TO MINT" value={deadline} format="D d HH:mm:ss" onFinish={refreshOnFinish} />
    //<Countdown title="COUNTDOWN TO MINT" value={deadline} format="D d HH:mm:ss"/>
  );

  //className="mint-outofnft" 
  const outOfNFTs = (
    <p className="mint-outofnft ant-statistic-content-value">
        Sold out! Find us on <a href="https://paintswap.finance/marketplace/collections/0x600ea64f353085a0df6fba7335086951ee90f36f" target="_blank">Paintswap</a>.
    </p>
  );

  return (
    <section className="page banner-wrapper-nature">
      <ScrollElement
        className="page"
        id="banner"
        onChange={({ mode }) => onEnterChange(mode)}
        playScale={0.9}
      >
        <QueueAnim className="banner-text-wrapper" type={typeFunc} delay={300} key="banner">
          {/*<h2 key="h2">NATURE <p>ORACLE</p></h2>*/}
          <p key="content">A collection of NFT Oracle Cards on Fantom Opera</p>
          <div className="ant-statistic-content-value">
          <p>
            Sold out! Find us on:
          </p>
          <a href="https://paintswap.finance/marketplace/collections/0x600ea64f353085a0df6fba7335086951ee90f36f" target="_blank"><Image className="paintswap-icon" width={"70px"} src={"img/paintswap_icon.png"} preview={false}/></a>
          <a href="https://nftkey.app/collections/natureoracle/" target="_blank"><Image className="paintswap-icon" height={"70px"} src={"img/nftkey.svg"} preview={false}/></a>
          </div>
          {/* <p className="mint-outofnft">
            Find us on <a href="https://paintswap.finance/marketplace/collections/0x600ea64f353085a0df6fba7335086951ee90f36f" target="_blank"><Image width={"50px"} src={"img/paintswap_icon.png"} preview={false}/></a>
          </p> */}
        </QueueAnim>
        <TweenOne
          animation={{
            y: '+=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="down"
          key="icon"
        >
          <DownOutlined className="down"/>
        </TweenOne>
      </ScrollElement>
    </section>
  );
}
NatureBanner.propTypes = {
  onEnterChange: PropTypes.func,
};
