import { Statistic, Tabs, Button } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { notification } from "antd";
import { useContractLoader } from "../hooks";
import { Row, Col, Image } from 'antd';
import {  Typography  } from 'antd';

import title_glimpse from "./img/SVG/title_bitwitches_collection.svg";

const { Text } = Typography;

const DEBUG = false;

var myHeaders = new Headers();
myHeaders.append('pragma', 'no-cache');
myHeaders.append('cache-control', 'no-cache');

var myInit = {
  method: 'GET',
  headers: myHeaders,
}; 

async function fetchJSON(url) {
    const resp = await fetch(url, myInit);
    if (resp.ok) {
        return resp.json();
    }
    else {
        return undefined;
    }
    
}

async function getListOwnedBO(address, contract, nftBalance) {
   
    let tokenmetadata = [];
    // Loop over all the token own and record their Ids
    for (let i = 0; i < nftBalance; i++) {
        if (address) {
            let tokenid = await contract.tokenOfOwnerByIndex(address, i);
            let metadataURI = await contract.tokenURI(tokenid.toNumber());
            let metadata = await fetchJSON(metadataURI);
            DEBUG && console.log(metadata);
            if (metadata) {
                tokenmetadata.push(metadata);
            }
        }
    }
    
    return tokenmetadata;
}

export default function FreeBannerGallery({
  nftbalance,
  provider,
  address,
  web3Modal
}) {
  const contracts = useContractLoader(provider);
  let contract;
  contract = contracts ? contracts["BannersOracle"] : "";
  const nftBalance = nftbalance ? nftbalance.toNumber() : 0;
  
  const [notificationFired, setNotificationFired] = useState(false);
  const [walletConnected, setWalletConnected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  // Entry counting related states
  const [listOwnedBO, setListOwnedBO] = useState([]);

  if (address && contract && nftBalance && (listOwnedBO.length < nftBalance)){
      // Try to retrieve the list of owned Banners Oracle token
      //DEBUG && console.log('tokens owned: ', list);
      let add = address;
      let cnt = contract;
      let bal = nftBalance;
      getListOwnedBO(add, cnt, bal).then(tokenlist => {
        setListOwnedBO(tokenlist);
        setWalletConnected(true);
        setLoading(false);
        setErrorMessage("");
        DEBUG && console.log('tokens owned: ', tokenlist);
      }).catch(e => {
        console.log('error getting token: ', e);
        setLoading(false);
        setErrorMessage(e.message);
        //setListOwnedBO([]);
      })
  }

  useEffect(() => {
    if (web3Modal) {
        if (!web3Modal.cachedProvider) {
           // no wallet is connected
           if (!notificationFired) {
              setNotificationFired(true);
              notification.info({
                message: "Please connect Wallet",
                description: "A wallet connected to Fantom Opera is required for minting",
                duration: 60,
                placement: "bottomRight",
              });
            }
        }
        else {
            setWalletConnected(true);
        }
      }    
  }, [])

  // Create the collection of owner Witches Oracles
  let BOCollection = (
    <div>
        <Row><Col md={24} sm={24}>You will be able to discover your minted BitWitches Oracle Banners here...</Col></Row>
    </div>
  );

  if (!loading && listOwnedBO) {
    let columnwidth = 24;

    BOCollection = (
      <Row>
        {listOwnedBO.map(item => (
          <Col md={columnwidth} sm={24}>
            <Image className="nftgallery-banner-img" src={item.image} preview={false}/>
          </Col>
        ))}
      </Row>
    );
  }

return (
    <div id="nft-gallery">
        <div className="container">
            <div id="nft-banner-gallery-body">
                {/* Add here a title - your Witches Oracle*/}
                <Image src={title_glimpse} height={"170px"} preview={false} style={{margin: "auto"}}/>
                {BOCollection}
                {errorMessage}
            </div>
        </div>
    </div>
  );}
