import { StaticJsonRpcProvider, Web3Provider } from "@ethersproject/providers";
import { formatEther, parseEther, formatUnits } from "@ethersproject/units";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { BigNumber } from "@ethersproject/bignumber";
import { Alert, Button, Col, Menu, Row, BackTop } from "antd";
import { UpCircleFilled } from '@ant-design/icons';
import "antd/dist/antd.css";
import { useUserAddress } from "eth-hooks";
import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Link, Route, Switch } from "react-router-dom";
import Web3Modal from "web3modal";
import "./App.css";
import { Account, Contract, Faucet, GasGauge, Header, Header3, Content3, Minter, MintButton, NFTViewer, Ramp, ThemeSwitch, WitchesShowroom, WitchesAbout,
         Banner, Timeline, Team, Team2, Separator, Faq, Rarity, About, Footer, Showroom, NFTGallery, NFTReadings, Roadmap, Raffle, WitchesBanner, WitchesGallery, Verification,
         NatureBanner, NatureAbout, NatureShowroom, HauntedWitchesMint, HauntedWitchesGallery, BitWitchesRitualsGallery } from "./components";
import { INFURA_ID, NETWORK, NETWORKS, MAX_MINTABLE_NFT, MAX_MINTABLE_NFT_WITCHES } from "./constants";
import { Transactor } from "./helpers";
import { enquireScreen } from 'enquire-js';
import { tryToDisplay, tryToDisplayAsText } from "./components/Contract/utils";
import {
  useBalance,
  useContractLoader,
  useContractReader,
  useEventListener,
  useExchangePrice,
  useExternalContractLoader,
  useGasPrice,
  useOnBlock,
  useUserProvider,
} from "./hooks";

import {
  Nav00DataSource,
  Teams10DataSource,
  Content30DataSource,
} from './components/data.source';

/// 📡 What chain are your contracts deployed to? localhost, ftmtest, opera
const targetNetwork = NETWORKS.mainnetAvalanche; // <------- select your target frontend network (localhost, rinkeby, xdai, mainnet)

// 😬 Sorry for all the console logging
const DEBUG = false;
const DEV = false;

// 🛰 providers
// 🏠 Your local provider is usually pointed at your local blockchain
const localProviderUrl = targetNetwork.rpcUrl;
// as you deploy to other networks you can set REACT_APP_PROVIDER=https://dai.poa.network in packages/react-app/.env
const localProviderUrlFromEnv = process.env.REACT_APP_PROVIDER ? process.env.REACT_APP_PROVIDER : localProviderUrl;
if (DEBUG) console.log("🏠 Connecting to provider:", localProviderUrlFromEnv);
const localProvider = new StaticJsonRpcProvider(localProviderUrlFromEnv);

// 🔭 block explorer URL
const blockExplorer = targetNetwork.blockExplorer;

/*
  Web3 modal helps us "connect" external wallets:
*/
const web3Modal = new Web3Modal({
  // network: "mainnet", // optional
  cacheProvider: true, // optional
  theme: {
    background: "rgb(66, 44, 60)",
    main: "rgb(222, 118, 123)",
    secondary: "rgb(136, 136, 136)",
    border: "rgba(195, 195, 195, 0.14)",
    hover: "rgb(48, 27, 42)"
  },  
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: INFURA_ID,
      },
    },
  },
});

const logoutOfWeb3Modal = async () => {
  await web3Modal.clearCachedProvider();
  setTimeout(() => {
    window.location.reload();
  }, 1);
};

function App(props) {

  const [injectedProvider, setInjectedProvider] = useState();

  /* 🔥 This hook will get the price of Gas from ⛽️ EtherGasStation */
  const gasPrice = useGasPrice(targetNetwork, "fast");
  // Use your injected provider from 🦊 Metamask or if you don't have it then instantly generate a 🔥 burner wallet.
  const userProvider = useUserProvider(injectedProvider, localProvider);
  const address = useUserAddress(userProvider);

  // You can warn the user if you would like them to be on a specific network
  const localChainId = localProvider && localProvider._network && localProvider._network.chainId;
  const selectedChainId = userProvider && userProvider._network && userProvider._network.chainId;

  // For more hooks, check out 🔗eth-hooks at: https://www.npmjs.com/package/eth-hooks

  // The transactor wraps transactions and provides notificiations
  const tx = Transactor(userProvider, gasPrice);

  // Faucet Tx can be used to send funds from the faucet
  const faucetTx = Transactor(localProvider, gasPrice);

  // 🏗 scaffold-eth is full of handy hooks like this one to get your balance:
  // const yourLocalBalance = useBalance(localProvider, address);
  const yourLocalBalance = useBalance(userProvider, address);

  // Load in your local 📝 contract and read a value from it:
  const readContracts = useContractLoader(localProvider);

  // If you want to make 🔐 write transactions to your contracts, use the userProvider:
  const writeContracts = useContractLoader(userProvider);

  //
  // 🧫 DEBUG 👨🏻‍🔬
  //
  useEffect(() => {
    if (
      DEBUG &&
      address &&
      selectedChainId &&
      yourLocalBalance &&
      readContracts &&
      writeContracts
    ) {
      console.log("_____________________________________ 🏗 scaffold-eth _____________________________________");
      console.log("🏠 localChainId", localChainId);
      console.log("👩‍💼 selected address:", address);
      console.log("🕵🏻‍♂️ selectedChainId:", selectedChainId);
      console.log("💵 yourLocalBalance", yourLocalBalance ? formatEther(yourLocalBalance) : "...");
      console.log("📝 readContracts", readContracts);
      console.log("🔐 writeContracts", writeContracts);
    }
  }, [
    address,
    selectedChainId,
    yourLocalBalance,
    readContracts,
    writeContracts,
  ]);

  // Maintain the total supply
  // UNCOMMENT FOR PROD
  const totalSupply = useContractReader(readContracts, "MarbleMaidens", "totalSupply", 10000);
  const mintingFee = useContractReader(readContracts, "MarbleMaidens", "getPrice", 10000);
  const mintingPreSaleFee = useContractReader(readContracts, "MarbleMaidens", "getPresalePrice", 10000);
  // COMMENT FOR PROD
  // const totalSupply = BigNumber.from(MAX_MINTABLE_NFT_WITCHES);
  // const mintingFee = 90;

  //const mintRemaining = totalSupply ? (MAX_MINTABLE_NFT - totalSupply.toNumber()) > 0 : false;
  const mintRemaining = false;
  const badConnection = totalSupply ? false : true;

  if (DEBUG) console.log(" address", tryToDisplay(address));
  //const balanceNFT = useContractReader(readContracts, "NatureOracleNFTMinter", "balanceOf", [address], 10000);
  // UNCOMMENT FOR PROD
  //const balanceWITO = useContractReader(readContracts, "WitchesOracle", "balanceOf", [address], 10000);
  //const balanceBO = useContractReader(readContracts, "BannersOracle", "balanceOf", [address], 10000);

  const balanceHW = useContractReader(readContracts, "MarbleMaidens", "balanceOf", [address], 10000);
  //const balanceBWRT = useContractReader(readContracts, "BitWitchesRituals", "balanceOf", [address], 10000);
  const whiteListEnabled = useContractReader(readContracts, "MarbleMaidens", "whiteListed", [address], 10000);
  const whiteListEnabledFlag = useContractReader(readContracts, "MarbleMaidens", "openWhitelistMint", 10000);
  // COMMENT FOR PROD
  // const balanceWITO = 0;
  // const whiteListEnabled=false;

  // if (DEBUG) console.log("totalSupply", tryToDisplay(totalSupply));
  // if (DEBUG) console.log("balanceHW", tryToDisplay(balanceNFT));
  // if (DEBUG) console.log("mintingFee", tryToDisplay(mintingFee));
  if (DEBUG) console.log("whiteListEnabledFlag", whiteListEnabledFlag);
  
  // State variable telling whether the user is connected to the right network

  let networkDisplay = "";
  if (localChainId && selectedChainId && localChainId !== selectedChainId) {
    const networkSelected = NETWORK(selectedChainId);
    const networkLocal = NETWORK(localChainId);
    if (selectedChainId === 1337 && localChainId === 31337) {
      networkDisplay = (
        <div style={{ zIndex: 2, position: "absolute", right: 0, top: 60, padding: 16 }}>
          <Alert
            message="⚠️ Wrong Network ID"
            description={
              <div>
                You have <b>chain id 1337</b> for localhost and you need to change it to <b>31337</b> to work with
                HardHat.
                <div>(MetaMask -&gt; Settings -&gt; Networks -&gt; Chain ID -&gt; 31337)</div>
              </div>
            }
            type="error"
            closable={false}
          />
        </div>
      );
    } else {
      networkDisplay = (
        <div style={{ zIndex: 2, position: "absolute", right: 0, top: 60, padding: 16 }}>
          <Alert
            message="⚠️ WRONG NETWORK"
            description={
              <div>
                You need to be on{" "}<b>{networkLocal && networkLocal.name}</b> - chain:{" "}{localChainId}
              </div>
            }
            type="error"
            closable={false}
          />
        </div>
      );
    }
  } else {
    // networkDisplay = (
    //   <div style={{ zIndex: -1, position: "absolute", right: 154, top: 28, padding: 16, color: targetNetwork.color }}>
    //     {targetNetwork.name}
    //   </div>
    // );
    networkDisplay = "";
  }

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new Web3Provider(provider));
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  const [route, setRoute] = useState();
  useEffect(() => {
    setRoute(window.location.pathname);
  }, [setRoute]);

  const [isFirstScreen, setIsFirstScreen] = useState(true);
  const onEnterChange = (mode) => {
    setIsFirstScreen(mode === 'enter');
  }

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    enquireScreen((b) => {
      setIsMobile(b);
    });
  }, [setIsMobile]);

  // State variable telling whether the collection is fully minted
  // const [isFullyMinted, setIsFullyMinted] = useState(totalSupply ? MAX_MINTABLE_NFT == totalSupply.toNumber() : false);
  const isFullyMinted = true;
  // useEffect(() => {
  //   if (totalSupply) {
  //     console.log(totalSupply.toNumber());
  //     setIsFullyMinted(MAX_MINTABLE_NFT === totalSupply.toNumber());
  //   }
  //   setIsFullyMinted(true);
  // }, [setIsFullyMinted]);
  

  let faucetHint = "";
  const faucetAvailable = localProvider && localProvider.connection && targetNetwork.name === "localhost";

  if (faucetAvailable) {
    faucetHint = (
      <div style={{ padding: 16 }}>
        <Button
          type="primary"
          onClick={() => {
            faucetTx({
              to: address,
              value: parseEther("0.01"),
            });
          }}
        >
          💰 Grab funds from the faucet ⛽️
        </Button>
      </div>
    );
  }
  
  return (
    <div className="App">
      <Header3
        id="Nav0_0"
        key="Nav0_0"
        dataSource={Nav00DataSource}
        isMobile={isMobile}
        address={address}
        web3Modal={web3Modal}
        loadWeb3Modal={loadWeb3Modal}
        logoutOfWeb3Modal={logoutOfWeb3Modal}  
      />
      {networkDisplay}
      <BrowserRouter>
        <Switch>
          {/* <Route path="/rituals">
            {balanceBWRT && 
            <BitWitchesRitualsGallery
              nftbalance={balanceBWRT}
              provider={userProvider}
              address={address}
              web3Modal={web3Modal}
              signer={userProvider.getSigner()}
            />
            }
          </Route> */}
          <Route path="/">
            <HauntedWitchesMint
              signer={userProvider.getSigner()}
              provider={userProvider}
              address={address}
              blockExplorer={blockExplorer}
              totalSupply={totalSupply}
              price={mintingFee}
              presaleprice={mintingPreSaleFee}
              balance={yourLocalBalance}
              web3Modal={web3Modal}
              loadWeb3Modal={loadWeb3Modal}
              logoutOfWeb3Modal={logoutOfWeb3Modal}    
              whiteListEnabled={whiteListEnabled} 
              whiteListEnabledFlag={whiteListEnabledFlag}   
            />
            {balanceHW && 
            <HauntedWitchesGallery
              nftbalance={balanceHW}
              provider={userProvider}
              address={address}
              web3Modal={web3Modal}
              signer={userProvider.getSigner()}
            />
            }
          </Route>   
        </Switch>
      </BrowserRouter>
      <Separator 
        id={1}
      />
      <Footer />
      <BackTop>
        <UpCircleFilled style={{ fontSize: '28px', color: '#f1c4a7' }}/>
      </BackTop>     
      {DEV && 
        <Minter
          signer={userProvider.getSigner()}
          provider={localProvider}
          address={address}
          blockExplorer={blockExplorer}
          name="MarbleMaidens"
        />
      }
      {DEV && 
      <Contract
        name="MarbleMaidens"
        signer={userProvider.getSigner()}
        provider={userProvider}
        address={address}
        blockExplorer={blockExplorer}
      />
      }
    </div>
  );
}

/* eslint-disable */
window.ethereum &&
  window.ethereum.on("chainChanged", chainId => {
    web3Modal.cachedProvider &&
      setTimeout(() => {
        window.location.reload();
      }, 1);
  });

window.ethereum &&
  window.ethereum.on("accountsChanged", accounts => {
    web3Modal.cachedProvider &&
      setTimeout(() => {
        window.location.reload();
      }, 1);
  });
/* eslint-enable */

export default App;
