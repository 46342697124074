import { Image } from "antd";
import React from "react";
import separator1 from "./img/separator_1.png";
import separator2 from "./img/separator_2.png";
import separator3 from "./img/separator_3.png";
import separator4 from "./img/separator_4.png";
import separator5 from "./img/separator_5.png";
import separator6 from "./img/separator_6.png";

// displays a separator

export default function Separator(props) {
  const id = props.id;
  var imageToLoad = 1;

  if (id === 1){
      imageToLoad = separator1;
  }
  else if (id === 2){
    imageToLoad = separator2;
  }
  else if (id === 3){
    imageToLoad = separator3;
  }
  else if (id === 4){
    imageToLoad = separator4;
  }
  else if (id === 5){
    imageToLoad = separator5;
  }
  else if (id === 6){
    imageToLoad = separator6;
  }

  return (
    <Image className="separator-img" className={props.inverted} src={imageToLoad} width={props.width} preview={false}/>
  );
}
