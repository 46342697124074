import React from "react";
import { TwitterOutlined, MediumCircleFilled } from "@ant-design/icons";
import Icon from '@ant-design/icons';
import { Image, Divider } from 'antd';
import {  Typography  } from 'antd';
import decor2 from "./img/decor_2.jpg";
import { FaDiscord, FaMedium, FaTwitter } from "react-icons/fa";

const { Text } = Typography;

// displays the FOOTER

export default function Footer(props) {
  return (
    <div id="footer" className="container">
      <div>
        <Image id="footer-logo" src={"img/logo_website.png"} preview={false}/>
        <TwitterOutlined className="footer-social-icon" style={{ fontSize: '28px', color: '#f1c4a7' }} onClick={() => {window.open('https://twitter.com/NatureOracle', '_blank');}}/>
        <MediumCircleFilled className="footer-social-icon" style={{ fontSize: '28px', color: '#f1c4a7' }} onClick={() => {window.open('https://medium.com/@NatureOracle', '_blank');}}/>
        {/* <FaTwitter className="anticon footer-social-icon" style={{ fontSize: '28px', color: '#f1c4a7' }} onClick={() => {window.open('https://medium.com/@NatureOracle', '_blank');}}/>
        <FaMedium className="anticon footer-social-icon" style={{ fontSize: '28px', color: '#f1c4a7' }} onClick={() => {window.open('https://medium.com/@NatureOracle', '_blank');}}/> */}
        <a href="https://discord.gg/duTABXQuJC" target="_blank"><FaDiscord className="anticon discord-icon footer-social-icon" style={{ fontSize: '28px', color: '#f1c4a7' }} /></a>
      </div>        
      <Divider style={{border: '1px solid #f1c4a7'}}/>
      <Image className="nature-decor" src={decor2} preview={false}/>
      <div>
        <Text>© 2023 Nature Oracle</Text>
      </div>
      <div>
        <span className="ant-typography">If you have any question, email us on <a href="mailto:info@natureoracle.com">info@natureoracle.com</a></span>
      </div>
      <div>
        <span className="ant-typography">Follow <a href="https://twitter.com/kay_wren_" target="_blank">@kay_wren_</a> on twitter to avoid missing out or join our <a href="https://discord.gg/cFkp6vQp" target="_blank">Discord Server</a></span>
      </div>
      <div>
        <span className="ant-typography">Follow us on <a href="https://medium.com/@NatureOracle" target="_blank">Medium</a> for the latest updates</span>
      </div>
    </div>
  );
}
