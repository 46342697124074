import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import ScrollElement from 'rc-scroll-anim/lib/ScrollElement';
import TweenOne from 'rc-tween-one';
import { DownOutlined } from '@ant-design/icons';
import { Statistic, Image, Tooltip } from "antd";
import ConnectWallet from "./ConnectWallet.jsx";
import WitchesMintButton from "./WitchesMintButton.jsx";
import slider from "./img/witches_anim.gif";
import QueueAnim from 'rc-queue-anim';
import { MAX_MINTABLE_NFT_WITCHES } from "../constants";

function typeFunc(a) {
    if (a.key === 'line') {
      return 'right';
    } else if (a.key === 'button') {
      return 'bottom';
    }
    return 'left';
  }
  
// DEBUG FLAG - set to false in prod
const DEBUG = false;

const { Countdown } = Statistic;

// WITCHES ORACLE LAUNCH
// UNCOMMENT FOR PROD
const MINT_START = new Date(Date.UTC(2022, 2, 18, 18, 0, 0, 0)); //18H UTC 1PM CST
const MINT_PUBLIC_START = new Date(Date.UTC(2022, 2, 18, 20, 0, 0, 0)); //18H UTC 1PM CST

// TEST CODE
// const MINT_START = new Date(Date.UTC(2022, 2, 18, 15, 35, 30, 0)); //18H UTC 1PM CST
// const MINT_PUBLIC_START = new Date(Date.UTC(2022, 2, 18, 15, 57, 0, 0)); //18H UTC 1PM CST

const calculateTimeLeft = () => {
  let difference = MINT_PUBLIC_START - +new Date();

  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      in: "The public sale will open ",
      day: Math.floor(difference / (1000 * 60 * 60 * 24)),
      h: Math.floor((difference / (1000 * 60 * 60)) % 24),
      min: Math.floor((difference / 1000 / 60) % 60),
      s: Math.floor((difference / 1000) % 60)
    };
  }

  return timeLeft;
}

const refreshOnFinish = () => {
  setTimeout(() => {
    window.location.reload();
  }, 1);
};

export default function WitchesBanner({ 
  onEnterChange,
  gasPrice,
  signer,
  provider,
  address,
  blockExplorer,
  totalSupply,
  price,
  presaleprice,
  balance,
  web3Modal,
  loadWeb3Modal,
  logoutOfWeb3Modal,
  whiteListEnabled,
  }) {

  // WITCHES ORACLE LAUNCH
  // UNCOMMENT FOR PROD
  const [isCountdownFinished, setIsCountdownFinished] = useState(new Date() >= MINT_START);
  //const [isCountdownFinished, setIsCountdownFinished] = useState(true);
  const [isPublicSaleEnabled, setIsPublicSaleEnabled] = useState(new Date() >= MINT_PUBLIC_START);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const mintRemaining = totalSupply ? (MAX_MINTABLE_NFT_WITCHES - totalSupply.toNumber()) > 0 : false;
  //const mintRemaining = true;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCountdownFinished(new Date() >= MINT_START);
      setIsPublicSaleEnabled(new Date() >= MINT_PUBLIC_START);
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });  

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key="{interval}">
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  if (DEBUG) console.log("provider", provider.connection.url);
  if (DEBUG) console.log("address", address);
  
  const countdown = (
    //<Countdown title="COUNTDOWN TO MINT" value={MINT_START} format="D d HH:mm:ss" onFinish={refreshOnFinish} />
    <Countdown title="COUNTDOWN TO MINT" value={MINT_START} format="D d HH:mm:ss"/>
  );

  const countdownPublicSale = (
    <p>
    {timerComponents}
    </p>
  );

  //className="mint-outofnft" 
  const outOfNFTs = (
    <p className="mint-outofnft ant-statistic-content-value">
        Sold out! Find us on <a href="https://paintswap.finance/marketplace/collections/0x600ea64f353085a0df6fba7335086951ee90f36f" target="_blank">Paintswap</a>.
    </p>
  );

  return (
    <section className="page banner-wrapper-witches">
      <div className="witches-container">
        <div className="witches-mint-container">
          <p key="content" className="content-witches">A collection of NFT Witches on Fantom by Zoey</p>
          <Image className="banner-wrapper-img" src={slider} preview={false}/>
          {!isCountdownFinished && countdown}
          {isCountdownFinished && !isPublicSaleEnabled && timerComponents.length && countdownPublicSale}
          <p>
            SOLD OUT! Find the Witches Oracle collection on Fantom marketplaces:
          </p>
          <Tooltip title={"OperaHouse"} color={'#de767b'} key={'#de767b'} placement="bottom">
            <a href="https://operahouse.online/collection/WitchesOracle" target="_blank"><Image className="paintswap-icon" width={"70px"} src={"img/oh.png"} preview={false}/></a>
          </Tooltip>
          <Tooltip title={"PaintSwap"} color={'#de767b'} key={'#de767c'} placement="bottom">
          <a href="https://paintswap.finance/marketplace/collections/0x0fb9d7cc9a3fbfc62a71182552a576859ae0c758" target="_blank"><Image className="paintswap-icon" width={"70px"} src={"img/paintswap_icon.png"} preview={false}/></a>
          </Tooltip>
          <Tooltip title={"NFTKEY"} color={'#de767b'} key={'#de767d'} placement="bottom">
          <a href="https://nftkey.app/collections/witchesoracle/" target="_blank"><Image className="paintswap-icon" height={"70px"} src={"img/nftkey.svg"} preview={false}/></a>
          </Tooltip>
          {/* {isCountdownFinished && provider.connection.url === 'unknown:' && mintRemaining && 
            <ConnectWallet
              address={address}
              web3Modal={web3Modal}
              loadWeb3Modal={loadWeb3Modal}
              logoutOfWeb3Modal={logoutOfWeb3Modal}
            />  
          } 
          {isCountdownFinished && (provider.connection.url !== 'unknown:' || !mintRemaining) && 
            <WitchesMintButton
              signer={signer}
              provider={provider}
              address={address}
              blockExplorer={blockExplorer}
              totalSupply={totalSupply}
              price={price}
              presaleprice={presaleprice}
              balance={balance}
              whiteListEnabled={whiteListEnabled}
              publicSaleEnabled={isPublicSaleEnabled}
            />
          }  */}
          </div>
        </div>
        <TweenOne
          animation={{
            y: '+=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="down"
          key="icon"
        >
          <DownOutlined className="down"/>
        </TweenOne>
    </section>
  );
}
WitchesBanner.propTypes = {
  onEnterChange: PropTypes.func,
};
