import { DownloadOutlined, EditOutlined, SendOutlined, ClockCircleOutlined, CheckOutlined, EuroTwoTone } from "@ant-design/icons";
import { Timeline, Typography, Image } from "antd";
import React from "react";
import TweenOne from 'rc-tween-one';
import Blockies from "react-blockies";
import title_roadmap from "./img/SVG/title_roadmap.svg";
import Citation from "./Citation.jsx";

const { Text } = Typography;

// displays a timeline for scaffold-eth usage

export default function TimelineDisplay(props) {
  return (
    <div id="timeline">
    <Image src={title_roadmap} height={"170px"} preview={false}/>
    <Citation 
            citation="The goal of life is to make your heartbeat match the beat of the universe, to match your nature with Nature."
            author="Joseph Campbell"
    />
    <Timeline mode="alternate">
      <Timeline.Item dot={<CheckOutlined style={{ fontSize: '22px', color: '#f1c4a7' }} />}>
        <Text className="timeline-emphasis" strong>Website launched</Text>
      </Timeline.Item>

      <Timeline.Item dot={<EuroTwoTone twoToneColor={"#f1c4a7"} style={{ fontSize: '22px'}} />}>
          <Text>
            Next step: Minting...{"\n"}
          </Text>
          <span className="ant-typography">Follow us on <a href="https://twitter.com/NatureOracle" target="_blank">Twitter</a>, <a href="https://medium.com/@NatureOracle" target="_blank">Medium</a>. Discord coming soon...</span>
        </Timeline.Item>

      <Timeline.Item dot={<ClockCircleOutlined style={{ fontSize: '22px' }} />}>
        <Text>
{/*
        <Text delete>
        <Text delete={props.chainIsUp}>
          Clone and Install from the{" "}
          <a target="_blank" rel="noopener noreferrer" href="https://github.com/austintgriffith/scaffold-eth">
            github repo
          </a>
 */}        
        <Text className="timeline-emphasis" strong>30 Card sold</Text>{"\nAirdrop of 1 Oracle card to a lucky winner among the 30 cards owners."}
        </Text>
      </Timeline.Item>

      <Timeline.Item dot={<ClockCircleOutlined style={{ fontSize: '22px' }} />}>
        <Text>
          <Text className="timeline-emphasis" strong>60 cards sold</Text>{"\nAirdrop of 1 Oracle card to a lucky winner among the 60 cards owners."}
        </Text>
      </Timeline.Item>

      <Timeline.Item dot={<ClockCircleOutlined style={{ fontSize: '22px' }} />}>
        <Text >
        <Text className="timeline-emphasis" strong>90 cards sold</Text>{"\nAirdrop of 1 Oracle card to a lucky winner among the 90 cards owners."}
        </Text>
      </Timeline.Item>

      <Timeline.Item dot={<ClockCircleOutlined style={{ fontSize: '22px' }} />}>
        <Text >
        <Text className="timeline-emphasis" strong>143 cards sold</Text><Text strong>{"\nWe are super excited to celebrate with the following exclusive perks!\n"}</Text> 
        <span className="timeline-emphasis">- 3 winners of a personalized NFT Oracle card:</span> our artist will work with you to personalize an Oracle card. Then your card will be added as a NFT to the collection. This perk is VERY special and not found anywhere else. {"\n"}
        <span className="timeline-emphasis">- 3 exclusive card readings of 1 hour</span> with our experienced reader who is also the creative mind behind the Nature Oracle.{"\n"}
        <span className="timeline-emphasis">- 3 physical signed and printed art</span> mailed to 3 lucky winners
        </Text>
      </Timeline.Item>

    </Timeline>
    <h3 className="timeline-emphasis">AND ALSO ONCE THE MINTING IS OVER:</h3>
    <p className="timeline-text">
    {"Gallery opens on the website: you will be able to see the prediction of your card and its rarity level. \n \
    Listing on Paintswap + Artion."}
    </p>
    <p className="timeline-text">
    {"Generous future airdrops to Nature Oracle holders, exclusive access, huge discounts and privileges for our future projects! \n \
    Exclusive AMA and chat with our artists.\n \
    And much more functionality to come in the \"Nature Oracle\" universe!"}"
    </p>
    </div>
  );
}
