import React from "react";
import { Divider } from 'antd';

// displays the FAQ
//<span className="line" key="line" />

function Citation(props) {
  return (
    <div className="citation-block">
        <div className="citation-quote">
            {props.citation}
        </div>
        <hr className="citation-line"/>
        <div className="citation-author">
            {props.author}
        </div>
    </div>
  );
}

export default Citation;
