import { Statistic, Tabs, Button } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { notification } from "antd";
import { useContractLoader } from "../hooks";
import { Row, Col, Image } from 'antd';
import {  Typography  } from 'antd';

import title_raffle from "./img/SVG/title_lottery.svg";
import Citation from "./Citation.jsx";
import Separator from "./Separator.jsx";
import { dictNatureOraclePrediction } from './listnft';

const { TabPane } = Tabs;
const { Countdown } = Statistic;
const { Text } = Typography;

const DEBUG = false;

var myHeaders = new Headers();
myHeaders.append('pragma', 'no-cache');
myHeaders.append('cache-control', 'no-cache');

var myInit = {
  method: 'GET',
  headers: myHeaders,
}; 

//const RAFFLE_NATO_API = 'http://localhost/natoapi/public/raffle.php'
const RAFFLE_NATO_API = 'https://api.natureoracle.com/raffle.php'
//const RAFFLE_NATO_API = "https://retoolapi.dev/ORgPAA/natureoracle_raffle"
const RAFFLE_DURATION = 7;      // duration in days
const RAFFLE_ID = 8;            // increment manually for each raffle
const RAFFLE_PRIZE = "50 FTM"   // prize or reward
const RAFFLE_START = new Date(Date.UTC(2022, 6, 6, 12, 0, 0, 0)); // Remember that month January starts at 0 - UTC time - CST = UTC - 5H | 21h CST is 2 0 0 the day after
const RAFFLE_END = new Date(Date.UTC(2022, 6, 6, 12, 0, 0, 0)); // Remember that month January starts at 0
// const RAFFLE_START = new Date(Date.UTC(2022, 5, 7, 4, 17, 0, 0)); // Remember that month January starts at 0 - UTC time - CST = UTC - 5H | 21h CST is 2 0 0 the day after
// const RAFFLE_END = new Date(Date.UTC(2022, 5, 7, 4, 17, 0, 0)); // Remember that month January starts at 0

RAFFLE_END.setDate(RAFFLE_END.getDate() + RAFFLE_DURATION);

function makeGatewayURL(ipfsURI) {
    return ipfsURI.replace(/^ipfs:\/\//, "https://natureoracle.mypinata.cloud/ipfs/");
}
  
async function fetchIPFSJSON(ipfsURI) {
    const url = makeGatewayURL(ipfsURI);
    const resp = await fetch(url);
    return resp.json();
}
  
async function getListOwnedNATO2(address, contract, nftBalance) {
   
    let tokenrarities = [];
    // Loop over all the token own and record their Ids
    for (let i = 0; i < nftBalance; i++) {
        if (address) {
            let tokenid = await contract.tokenOfOwnerByIndex(address, i);
            let metadataURI = await contract.tokenURI(tokenid.toNumber());
            let metadata = await fetchIPFSJSON(metadataURI);
            let rarity = metadata.attributes[0].value;
            tokenrarities.push(rarity);
        }
    }
    
    return tokenrarities;
}

async function getListOwnedWITO(address, contract, nftBalance) {
   
  let tokenrarities = [];
  // Loop over all the token own and record their Ids
  for (let i = 0; i < nftBalance; i++) {
      if (address) {
          let tokenid = await contract.tokenOfOwnerByIndex(address, i);
          let metadataURI = await contract.tokenURI(tokenid.toNumber());
          let metadata = await fetchIPFSJSON(metadataURI);
          // Loop over the attributes and look for either a signed or a tribute or an element - others are 'common'
          let rarity = 'Common';
          for (let i in metadata.attributes) {
            if (metadata.attributes[i].trait_type === "Tribute") {
              rarity = 'Tribute';
            }
            else if (metadata.attributes[i].trait_type === "Signed By") {
              rarity = 'Signed';
            }
            else if ((metadata.attributes[i].trait_type === "Element") && (rarity != "Signed By")){
              rarity = 'Element';
            }
          }
          tokenrarities.push(rarity);
      }
  }
  
  return tokenrarities;
}

function getNumberEntriesWito(tokenrarities) {
  DEBUG && console.log('list', tokenrarities);

  let nbentries = 0;
  var rarity;
  for (let i in tokenrarities) {
      rarity = tokenrarities[i];
      if (rarity === "Common") {
          nbentries += 1;
      }
      else if (rarity === "Element") {
          nbentries += 2;
      }
      else if (rarity === "Tribute") {
          nbentries += 4;
      }
      else if (rarity === "Signed") {
          nbentries += 6;
      }
      DEBUG && console.log('step', rarity, nbentries);
  }

  return nbentries;
}


function getNumberEntries2(tokenrarities) {
    DEBUG && console.log('list', tokenrarities);

    let nbentries = 0;
    var rarity;
    for (let i in tokenrarities) {
        rarity = tokenrarities[i];
        if (rarity === "Common") {
            nbentries += 1;
        }
        else if (rarity === "Uncommon") {
            nbentries += 2;
        }
        else if (rarity === "Rare") {
            nbentries += 4;
        }
        else if (rarity === "Epic") {
            nbentries += 6;
        }
        DEBUG && console.log('step', rarity, nbentries);
    }

    return nbentries;
}

export default function Raffle({
  nftbalance,
  nftwitobalance,
  provider,
  address,
  web3Modal
}) {
  const contracts = useContractLoader(provider);
  let contract;
  let contractwito;
  contract = contracts ? contracts["NatureOracleNFTMinter"] : "";
  contractwito = contracts ? contracts["WitchesOracle"] : "";
  //console.log('nft readings address', address);
  //const address = contract ? contract.address : "";
  const nftBalance = nftbalance ? nftbalance.toNumber() : 0;
  const nftBalanceWito = nftwitobalance ? nftwitobalance.toNumber() : 0;
  // Eligible to participate with at least one
  const nftTotalBalance = nftBalance + nftBalanceWito;
  const eligibilityConfirmed = (nftBalance + nftBalanceWito) >= 1;

  const [notificationFired, setNotificationFired] = useState(false);
  const [walletConnected, setWalletConnected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  // Raffle activation related states
  const [raffleStarted, setRaffleStarted] = useState(new Date() >= RAFFLE_START);
  const [raffleLive, setRaffleLive] = useState((new Date() >= RAFFLE_START) && (new Date() < RAFFLE_END));
  const [raffleFinished, setRaffleFinished] = useState(new Date() >= RAFFLE_END);

  // Entry counting related states - Nature Oracle
  const [listOwnedNATO, setListOwnedNATO] = useState([]);
  const [numberEntries, setNumberEntries] = useState(0);
  const [entryResponse, setEntryResponse] = useState(null);
  const [bEntryReceivedAlready, setbEntryReceivedAlready] = useState(false);

  // Entry counting related states - Witches Oracle
  const [listOwnedWITO, setListOwnedWITO] = useState([]);
  const [numberEntriesWito, setNumberEntriesWito] = useState(0);
  
//   if (web3Modal) {
//     if (!web3Modal.cachedProvider) {
//        // no wallet is connected
//        if (!notificationFired) {
//           setNotificationFired(true);
//           notification.info({
//             message: "Please connect Wallet",
//             description: "A Wallet with at least 1 NATO is required to access advanced reading spreads",
//             duration: 60,
//             placement: "bottomRight",
//           });
//         }
//     }
//     else {
//         setWalletConnected(true);
//     }
//   }

  // NATURE ORACLE TICKETS
  if (address && contract && nftBalance && (listOwnedNATO.length === 0)){
      // Try to retrieve the list of owned Nature Oracle token
      //setLoading(true);
      //let list = getListOwnedNATO(address, contract, nftBalance);
      //DEBUG && console.log('tokens owned: ', list);
      let add = address;
      let cnt = contract;
      let bal = nftBalance;
      getListOwnedNATO2(add, cnt, bal).then(tokenlist => {

        // Check if wallet has already participated
        if (address) {
            for (let id in raffleItems) {
                if (raffleItems[id].WALLET_ADDRESS === address){
                    setbEntryReceivedAlready(true);
                    setNumberEntries(raffleItems[id].NB_ENTRIES);
                }
            }    
        }

        setListOwnedNATO(tokenlist);
        setWalletConnected(true);
        setLoading(false);
        setErrorMessage("");
        DEBUG && console.log('tokens owned: ', tokenlist);
        if (!bEntryReceivedAlready) {
          let nbentries;
          nbentries = getNumberEntries2(tokenlist);
          setNumberEntries(nbentries + numberEntries);
          DEBUG && console.log('entries: ', nbentries);
        }
      }).catch(e => {
        console.log('error getting token: ', e);
        setLoading(false);
        setErrorMessage(e.message);
        setListOwnedNATO([]);
      })
  }

  // WITCHES ORACLE TICKETS
  if (address && contractwito && nftBalanceWito && (listOwnedWITO.length === 0)){
    // Try to retrieve the list of owned Witches Oracle tokens
    //DEBUG && console.log('tokens owned: ', list);
    let add = address;
    let cnt = contractwito;
    let bal = nftBalanceWito;
    getListOwnedWITO(add, cnt, bal).then(tokenlist => {

      // Check if wallet has already participated
      if (address) {
          for (let id in raffleItems) {
              if (raffleItems[id].WALLET_ADDRESS === address){
                // TODO to change
                  setbEntryReceivedAlready(true);
                  setNumberEntries(raffleItems[id].NB_ENTRIES);
              }
          }    
      }

      setListOwnedWITO(tokenlist);
      setWalletConnected(true);
      setLoading(false);
      setErrorMessage("");
      DEBUG && console.log('tokens owned: ', tokenlist);
      if (!bEntryReceivedAlready) {
        let nbentries;
        nbentries = getNumberEntriesWito(tokenlist);
        setNumberEntries(nbentries + numberEntries);
        DEBUG && console.log('entries: ', nbentries);
      }
    }).catch(e => {
      console.log('error getting token: ', e);
      setLoading(false);
      setErrorMessage(e.message);
      setListOwnedWITO([]);
    })
}


  // Query the raffle API to see current status
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [raffleItems, setRaffleItems] = useState([]);
  const [totalEntriesRecorded, setTotalEntriesRecorded] = useState(0);

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    fetch(RAFFLE_NATO_API, myInit)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setRaffleItems(result);
          let totalentries = 0;
          for (let item in result) {
            totalentries += result[item].NB_ENTRIES;
          }
          DEBUG && console.log('entries found so far ', totalentries);
          setTotalEntriesRecorded(totalentries);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  useEffect(() => {
    if (web3Modal) {
        if (!web3Modal.cachedProvider) {
           // no wallet is connected
           if (!notificationFired) {
              setNotificationFired(true);
              notification.info({
                message: "Please connect Wallet",
                description: "A Wallet with at least 1 NATO is required to access advanced reading spreads",
                duration: 60,
                placement: "bottomRight",
              });
            }
        }
        else {
            setWalletConnected(true);
        }
      }    
  }, [])

  // Results from the Rest API query of current participants
  let apiResults = ""
  if (error) {
    apiResults = <div>Error: {error.message}</div>;
  } 
  else if (!isLoaded) {
    apiResults = <div>Loading...</div>;
  } 
  else {
    // Debug - display the results from the API query on current participants
    apiResults = (
      <ul>
        {raffleItems.map(item => (
          <li key={item.id}>
            {item.WALLET_ADDRESS} {item.NB_ENTRIES}
          </li>
        ))}
      </ul>
    );
  }

  const resetRaffle = () => {
    axios.delete(RAFFLE_NATO_API + '/5');
    // const entry = { WALLET_ADDRESS: "default", 
    //                 NB_ENTRIES: 0 };
    // axios.patch('https://retoolapi.dev/dNA641/natureoracle_raffle/4',entry);
  }

  const testLocalAPI = () => {
    fetch("http://localhost/natoapi/metadata.php?id=2")
      .then(res => res.json())
      .then(
        (result) => {
          DEBUG && console.log('metadata ', result);
          // setIsLoaded(true);
          // setRaffleItems(result);
          // let totalentries = 0;
          // for (let item in result) {
          //   totalentries += result[item].NB_ENTRIES;
          // }
          // DEBUG && console.log('entries found so far ', totalentries);
          // setTotalEntriesRecorded(totalentries);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  const resetRaffleButton = (
    <Button className="button-minting" type="primary" disabled={false} onClick={testLocalAPI}> 
      Reset Raffle
    </Button>
  )

  const enterRaffle = () => {
    // The number of entries is numberEntries
    // The address is in address

    const token_aes = btoa('jefweahv' + address + 'gdgdf' + numberEntries);

    // set a post request to the RestAPI with wallet address and the number of entries
    const entry = { WALLET_ADDRESS: address, 
                    NB_ENTRIES: numberEntries,
                    TOKEN_AES: token_aes };

    axios.post(RAFFLE_NATO_API, entry)
        .then(response => setEntryResponse(response.data.response_desc + '. Good luck!'));
    // Post a confirmation of entries
    setbEntryReceivedAlready(true);
  }

  const enterRaffleButton = (
    <Button className="button-minting" type="primary" disabled={false} onClick={enterRaffle}>
      Record your participation to the Raffle!
    </Button>
  )

  const refreshOnFinish = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };  

  const countdownStart = (
    <Countdown className="raffle" title="THE LOTTERY WILL BEGIN IN..." value={RAFFLE_START} format="D d HH:mm:ss" onFinish={refreshOnFinish} />
  );

  const countdownEnd = (
    <Countdown className="raffle" title="THE LOTTERY WILL END IN..." value={RAFFLE_END} format="D d HH:mm:ss" onFinish={refreshOnFinish} />
  );

  const msgRaffleFinished = (
    <div>
        <Text>This lottery is over. Visit our Discord server to participate in the next one!</Text>
    </div>
  );

  const msgRaffleStatus = (
    <div>
        <span className="about-emphasis">{totalEntriesRecorded} tickets</span> have been entered in the lottery so far.
    </div>
  );

  const msgNoWallet = (
    <div>
        <Text>Please connect your wallet on Fantom Opera to determine eligibility</Text>
    </div>
  );

  const msgLoadingWallet = (
    <div>
        <div>
            <Text>Checking your balance of NATO and WO tokens in your wallet...</Text>
        </div>
        <div>
            <LoadingOutlined/>
        </div>
    </div>
  );

  const msgNoNATOToken = (
    <div>
        <Text>Sorry, but no Nature or Witches Oracle NFTs were found in your wallet. Please check...</Text>
    </div>
  );

  const msgAlreadyParticipated = (
    <div>
        <div>
            Your entry of {numberEntries} tickets for this lottery has been recorded.
        </div>
        <div>
            Results will be announced on July 13th on our Discord. <span className="about-emphasis">Thank you!</span>{"\n"}
        </div>
    </div>
  );

  const rafflesection = (
    <div>
        <div>
        Hello there!
        </div>
        <div>
        We found <span className="about-emphasis">{nftBalance} Nature Oracle NFTs</span> and <span className="about-emphasis">{nftBalanceWito} Witches Oracle NFTs</span> in your wallet.
        </div>
        <div>
        Based on their rarity, you have received <span className="about-emphasis">{numberEntries} tickets</span> for this lottery.
        </div>        
        {enterRaffleButton}
    </div>
  );

  return (
    <section id="nft-readings">
        <div className="container">
            <img className="nft-reading-title" src={title_raffle}/>
            <div>
                <Citation 
                citation={"Ask and it will be given to you \n \
                Seek and you will find \n \
                Knock and the door will be opened to you \n \
                "}
                author="Matthew 7:7"
                />
                <Row>
                    <Col md={5} sm={5}>
                    <Image className="nftreading-img" src="img/symbol_blue.png" preview={false} style={{maxWidth: "350px"}}/>
                    </Col>
                    <Col md={14} sm={14}>
                        <h2>
                        Welcome to Nature &amp; Witches Oracle Lottery <span className="about-emphasis">#{RAFFLE_ID}</span>
                        </h2>
                        <p className="timeline-text">
                          A new fortnight, a new cash prizes lottery for our holders!!{"\n"}
                          {"\n"}
                          Some small changes this week: <span className="about-emphasis">350 FTM</span>, to be shared among <span className="about-emphasis">7</span> winning tickets!{"\n"}
                          Each will win a nice and round cash prize of <span className="about-emphasis">{RAFFLE_PRIZE}</span>.{"\n"}
                          You can enter until July 13th and we will publish the results on our Discord server.
                        </p>

                        {/* Display the countdown until start if the raffle has not yet started */}
                        {!raffleStarted && countdownStart}
                        {/* Display the countdown until end if the raffle has started but is not yet finished */}
                        {raffleStarted && !raffleFinished && countdownEnd}
                        {/* Display the current status of the raffle*/}
                        {raffleStarted && msgRaffleStatus}
                        {/* Display message that this raffle is finished */}
                        {raffleFinished && msgRaffleFinished}
                        <Separator 
                          id={2}
                          width={"200px"}
                        />
                        {/* Display message that you need to connect your wallet */}
                        {raffleLive && !walletConnected && msgNoWallet}
                        {/* Display message no NATO token were detected */}
                        {raffleLive && walletConnected && !loading && (nftTotalBalance === 0) && msgNoNATOToken}
                        {/* If raffle has started but not yet finished, if eligible, display the participation content */}
                        {raffleStarted && !raffleFinished && eligibilityConfirmed && !bEntryReceivedAlready && rafflesection}
                        {/* If raffle has started but not yet finished, and still determining eligibility, display waiting */}
                        {raffleStarted && !raffleFinished && walletConnected && loading && msgLoadingWallet}
                        {/* If raffle has started and the connected wallet has already participated */}
                        <div>
                        {entryResponse}
                        </div>
                        {raffleStarted && bEntryReceivedAlready && msgAlreadyParticipated}                                          
                    </Col>
                    <Col md={5} sm={5}>
                    <Image className="nftreading-img" src="img/symbol_blue.png" preview={false} style={{maxWidth: "350px"}}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={18} sm={21}>
                        {/* Display a button when DEV to reset the records of the raffle participants */}
                        {DEBUG && resetRaffleButton}
                        <div>
                        {DEBUG && apiResults}
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    </section>
  );
}
