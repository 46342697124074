import { Statistic, Tabs, Button } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { notification } from "antd";
import { useContractLoader } from "../hooks";
import { Row, Col, Image } from 'antd';
import {  Typography  } from 'antd';

import title_glimpse from "./img/SVG/title_coven.svg";

const { Text } = Typography;

const DEBUG = false;

// rewrite ipfs:// uris to dweb.link gateway URLs
function makeGatewayURL(ipfsURI) {
    return ipfsURI.replace(/^ipfs:\/\//, "https://natureoracle.mypinata.cloud/ipfs/");
  }
  
async function fetchIPFSJSON(ipfsURI) {
    const url = makeGatewayURL(ipfsURI);
    const resp = await fetch(url);
    if (resp.ok) {
        return resp.json();
    }
    else {
        return undefined;
    }
    
}

function maskShowroom(title, prediction, rank) {
    return (
        <div>
            <p className="nftreading-mask-title">
              {title}
            </p>
            <p className="nftreading-mask-description">
              {prediction}
            </p>
        </div>
    )
}

async function getListOwnedWITO(address, contract, nftBalance) {
   
    let tokenmetadata = [];
    // Loop over all the token own and record their Ids
    for (let i = 0; i < nftBalance; i++) {
        if (address) {
            let tokenid = await contract.tokenOfOwnerByIndex(address, i);
            let metadataURI = await contract.tokenURI(tokenid.toNumber());
            let metadata = await fetchIPFSJSON(metadataURI);
            DEBUG && console.log(metadata);
            if (metadata) {
                tokenmetadata.push(metadata);
            }
        }
    }
    
    return tokenmetadata;
}

function getNumberEntries(tokenrarities) {
    DEBUG && console.log('list', tokenrarities);

    let nbentries = 0;
    var rarity;
    for (let i in tokenrarities) {
        rarity = tokenrarities[i];
        if (rarity === "Common") {
            nbentries += 1;
        }
        else if (rarity === "Uncommon") {
            nbentries += 2;
        }
        else if (rarity === "Rare") {
            nbentries += 4;
        }
        else if (rarity === "Epic") {
            nbentries += 6;
        }
        DEBUG && console.log('step', rarity, nbentries);
    }

    return nbentries;
}

export default function WitchesGallery({
  nftbalance,
  provider,
  address,
  web3Modal
}) {
  const contracts = useContractLoader(provider);
  let contract;
  contract = contracts ? contracts["WitchesOracle"] : "";
  const nftBalance = nftbalance ? nftbalance.toNumber() : 0;
  
  const [notificationFired, setNotificationFired] = useState(false);
  const [walletConnected, setWalletConnected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  // Entry counting related states
  const [listOwnedWITO, setListOwnedWITO] = useState([]);

  if (address && contract && nftBalance && (listOwnedWITO.length < nftBalance)){
      // Try to retrieve the list of owned Witches Oracle token
      //DEBUG && console.log('tokens owned: ', list);
      let add = address;
      let cnt = contract;
      let bal = nftBalance;
      getListOwnedWITO(add, cnt, bal).then(tokenlist => {
        setListOwnedWITO(tokenlist);
        setWalletConnected(true);
        setLoading(false);
        setErrorMessage("");
        DEBUG && console.log('tokens owned: ', tokenlist);
      }).catch(e => {
        console.log('error getting token: ', e);
        setLoading(false);
        setErrorMessage(e.message);
        //setListOwnedWITO([]);
      })
  }

  useEffect(() => {
    if (web3Modal) {
        if (!web3Modal.cachedProvider) {
           // no wallet is connected
           if (!notificationFired) {
              setNotificationFired(true);
              notification.info({
                message: "Please connect Wallet",
                description: "A wallet connected to Fantom Opera is required for minting",
                duration: 60,
                placement: "bottomRight",
              });
            }
        }
        else {
            setWalletConnected(true);
        }
      }    
  }, [])

  // Create the collection of owner Witches Oracles
  let witoCollection = (
    <div>
        <Row><Col md={24} sm={24}>Loading your Witches Oracles... <LoadingOutlined/></Col></Row>
        <Row><Col md={24} sm={24}><Image className="nftreading-img" src={"img/witches_oracle_cardback.jpg"} preview={false}/></Col></Row>
    </div>
  );

  if (!loading && listOwnedWITO) {
    let columnwidth = 12;
    if (listOwnedWITO.length === 1) {
        columnwidth = 24;
    }
    else if (listOwnedWITO.length === 2) {
        columnwidth = 12;
    }

    witoCollection = (
      <Row>
        {listOwnedWITO.map(item => (
          <Col md={columnwidth} sm={24}>
            <Image className="nftreading-img" src={makeGatewayURL(item.image)} preview={{ mask: maskShowroom(item.name, item.description_rich, item.rarity.rank), maskClassName: "nftreading-mask-style"}}
            placeholder={<Image src="img/witches_oracle_cardback.jpg"/>}/>
          </Col>
        ))}
      </Row>
    );
  }

return (
    <div id="nft-gallery">
        <div className="container">
            <div id="nft-gallery-body">
                {/* Add here a title - your Witches Oracle*/}
                <Image src={title_glimpse} height={"170px"} preview={false} style={{margin: "auto"}}/>
                {witoCollection}
                {errorMessage}
            </div>
        </div>
    </div>
  );}
